import { http } from '@/lib/http'

export default {
  /**
   * 获取Entity详情
   * @param {string} entityId
   */
  getEntityDetail: (entityId: number) => {
    return http.request('get', `/entity/${entityId}`)
  },
  /**
   * 获取项目列表接口
   */
  getProjectList: () => {
    return http.request('get', `/project`)
  },
  /**
   * 获取entity列表
   * @param {string} projectId
   */
  getEntityList: (projectId: number) => {
    return http.request('get', `/project/${projectId}/entity`)
  },
  /**
   * 获取项目成员列表
   * @param {string} projectId
   */
  getProjectMemberList: (projectId: number) => {
    return http.request('get', `/project/${projectId}/member`)
  },
  /**
   * 获取entity成员列表
   * @param {string} entityId
   */
  getEntityMemberList: (entityId: number) => {
    return http.request('get', `/entity/${entityId}/member`)
  },
  /**
   * 获取项目详情
   * @param {number} projectId
   */
  getProjectDetail: (projectId: number) => {
    return http.request('get', `/project/${projectId}`)
  }
}
