import type { Component } from 'vue'

// 全局组件注册
import ElvButton from './ElvButton.vue'
import ElvDatePicker from './ELvDatePicker.vue'

const components: {
  [propName: string]: Component
} = {
  ElvButton,
  ElvDatePicker
}

export default components
