import { http } from '@/lib/http'
import { ConfigType } from '#/GlobalConfigTypes'

export default {
  /**
   * 获取配置
   */
  getGlobalConfig: () => {
    return http.request<ResponseData<ConfigType>>('get', `/config`)
  },
  /**
   * 获取链列表
   */
  getChainList: () => {
    return http.request('get', `/global/chain`)
  },
  /**
   * 获取交易所列表
   */
  getExchangeList: () => {
    return http.request('get', `/global/exchange`)
  },
  /**
   * 获取银行列表
   */
  getBankList: () => {
    return http.request('get', `/global/bank`)
  }
}
