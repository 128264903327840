<template>
  <div v-loading="loading">
    <router-view />
  </div>
</template>
<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import '@/assets/fonts/font.css'
import '@/assets/style/common.scss'
import '@/assets/style/element-reset.scss'
import { useGlobalStore } from '@/stores/modules/global'

const loading = computed(() => {
  return !useGlobalStore().projectList.length
})

const { locale } = useI18n()

window.addEventListener(
  'message',
  async (e) => {
    if (e.data?.action) {
      const message = e.data
      if (e.data?.action) {
        if (message.action === 'change_language') {
          locale.value = message?.data?.language
          localStorage.setItem('language', message?.data?.language)
        }
      }
    }
  },
  false
)
</script>
<style scoped>
#app {
  font-family: 'Plus Jakarta Sans';
}
</style>
