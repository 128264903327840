import { http } from '@/lib/http'
import { RegisterParamsType, CompleteRegisterParamsType } from '#/UserTypes'

export default {
  /**
   * 获取验证码
   * @param {string} email
   * @returns
   */
  sendCaptcha: (email: string) => {
    return http.request('post', '/captcha', { data: { email } })
  },

  /**
   * 用户登录
   * @param {string} email
   * @param {number} code
   * @param {string} password
   * @returns
   */
  userLogin: (email: string, code?: number, password?: string) => {
    if (password) {
      return http.request('post', '/user/login', { data: { email, code: null, password } })
    }
    return http.request('post', '/user/login', { data: { email, code, password: null } })
  },

  /**
   * 注册
   * @param {object} data
   * @property {string} email
   * @property {string} name
   * @property {string} password
   * @property {string} referer?
   * @property {number} code
   * @param {string} history // 跳转地址
   * @returns
   */
  userRegister: (data: RegisterParamsType, history: string) => {
    return http.request('post', '/user/register', { data, headers: { 'elven-last-history': history } })
  },

  /**
   * 获取用户登录情况
   * @returns
   */
  getUser: () => {
    return http.request('get', '/user')
  },
  /**
   * 完善用户注册
   * @param {object} data
   * @property {string} name 用户名
   * @property {string} password 密码
   * @property {string} referer? 来源
   * @param {string} history // 跳转地址
   */
  userCompleteRegister: (data: CompleteRegisterParamsType, history: string) => {
    return http.request('post', '/user/completeRegister', { data, headers: { 'elven-last-history': history } })
  },
  /**
   * 用户导航排序
   * @param {object} data
   * @property {Array} navigationSort 导航排序id
   */
  userNavigationSort: (data: any) => {
    return http.request('post', '/user/navigationSort', { data: { navigationSort: data } })
  }
}
