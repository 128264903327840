import { $t } from '@/i18n/index'

// 报表交易类型
export const reportTransactionType = [
  { label: $t('report.operating'), value: 'OPERATING' },
  { label: $t('report.financing'), value: 'FINANCING' },
  { label: $t('report.investing'), value: 'INVESTING' }
]

export const transactionDataOptions = [
  { label: $t('report.completeHistory'), value: 'ALL' },
  { label: $t('report.dataBefore'), value: 'BEFORE' },
  { label: $t('report.dataAfter'), value: 'AFTER' },
  { label: $t('report.dataBetween'), value: 'BETWEEN' }
]

export const chainBrowserMap: any = {
  polygon: 'https://polygonscan.com/tx',
  arbitrum: 'https://arbiscan.io/tx',
  avalanche: 'https://snowtrace.io/tx',
  optimism: 'https://optimistic.etherscan.io/tx',
  ethereum: 'https://etherscan.io/tx',
  bsc: 'https://bscscan.com/tx',
  bitcoin: 'https://www.blockchain.com/btc/tx',
  tron: 'https://tronscan.org/#/transaction',
  arweave: 'https://viewblock.io/arweave/tx'
}

export const reportContactType = [
  { label: $t('report.employee'), value: 'Employee', icon: 'report-contact-Employee', color: '#EF7937' },
  { label: $t('report.vendor'), value: 'Vendor', icon: 'report-contact-Vendor', color: '#0AC18E' },
  { label: $t('report.customer'), value: 'Customer', icon: 'report-contact-Customer', color: '#474DFF' },
  { label: $t('report.investor'), value: 'Investor', icon: 'report-contact-Investor', color: '#FA413F' },
  {
    label: $t('report.community'),
    value: 'Community',
    icon: 'report-contact-Community',
    color: '#C2A633'
  },
  {
    label: $t('report.debtor'),
    value: 'Debtor',
    icon: 'report-contact-Debtor',
    color: '#E6007A'
  },
  {
    label: $t('report.sponsor'),
    value: 'Sponsor',
    icon: 'report-contact-Sponsor',
    color: '#F78580'
  }
]

export const financialsMenuList = ref<any[]>([
  { financialId: 'balance-sheet', name: $t('project.nav.balanceSheet') },
  { financialId: 'income-statement', name: $t('project.nav.incomeStatement') },
  { financialId: 'cash-flow-statement', name: $t('project.nav.cashFlowStatement') },
  { financialId: 'line', name: '' },
  { financialId: 'significant-holdings', name: $t('project.nav.SignificantHoldings') },
  { financialId: 'restrictions', name: $t('project.nav.Restrictions') },
  { financialId: 'roll-forward', name: $t('project.nav.RollForward') }
])

// 报表事件列表
export const reportEventList = [
  {
    categoryNo: '10000',
    primaryGrouping: 'Trading',
    subGrouping: 'Spot trading',
    categoryName_CN: '用户支付币币交易手续费',
    categoryName: 'User pays spot trading fees',
    Costbasis: true,
    BorrowingCostbasis: false,
    ReceivalbeCBS: false,
    tokenDirection: 'in',
    fiatDirection: '/'
  },
  {
    categoryNo: '10100',
    primaryGrouping: 'Trading',
    subGrouping: 'Leveraged trading',
    categoryName_CN: '用户杠杆借款',
    categoryName: 'User borrows leverage',
    Costbasis: true,
    BorrowingCostbasis: false,
    ReceivalbeCBS: true,
    tokenDirection: 'out',
    fiatDirection: '/'
  },
  {
    categoryNo: '10101',
    primaryGrouping: 'Trading',
    subGrouping: 'Leveraged trading',
    categoryName_CN: '用户杠杆还款',
    categoryName: 'User repays leverage',
    Costbasis: true,
    BorrowingCostbasis: false,
    ReceivalbeCBS: true,
    tokenDirection: 'in',
    fiatDirection: '/'
  },
  {
    categoryNo: '10102',
    primaryGrouping: 'Trading',
    subGrouping: 'Leveraged trading',
    categoryName_CN: '用户支付借款利息',
    categoryName: 'User pays loan interest',
    Costbasis: true,
    BorrowingCostbasis: false,
    ReceivalbeCBS: false,
    tokenDirection: '',
    fiatDirection: '/'
  },
  {
    categoryNo: '10103',
    primaryGrouping: 'Trading',
    subGrouping: 'Leveraged trading',
    categoryName_CN: '用户支付杠杆交易手续费',
    categoryName: 'User pays leverage trading fees',
    Costbasis: true,
    BorrowingCostbasis: false,
    ReceivalbeCBS: false,
    tokenDirection: '',
    fiatDirection: '/'
  },
  {
    categoryNo: '10104',
    primaryGrouping: 'Trading',
    subGrouping: 'Leveraged trading',
    categoryName_CN: '用户支付杠杆交易手续费-平台币',
    categoryName: 'User pays leverage trading fees - platform token',
    Costbasis: true,
    BorrowingCostbasis: false,
    ReceivalbeCBS: false,
    tokenDirection: '',
    fiatDirection: '/'
  },
  {
    categoryNo: '10201',
    primaryGrouping: 'Trading',
    subGrouping: 'Contract',
    categoryName_CN: '用户支付合约开仓手续费（taker）',
    categoryName: 'User pays contract opening fee (taker)',
    Costbasis: true,
    BorrowingCostbasis: false,
    ReceivalbeCBS: false,
    tokenDirection: '',
    fiatDirection: '/'
  },
  {
    categoryNo: '10202',
    primaryGrouping: 'Trading',
    subGrouping: 'Contract',
    categoryName_CN: '用户支付合约开仓手续费（maker）',
    categoryName: 'User pays contract opening fee (maker)',
    Costbasis: true,
    BorrowingCostbasis: false,
    ReceivalbeCBS: false,
    tokenDirection: '',
    fiatDirection: '/'
  },
  {
    categoryNo: '10203',
    primaryGrouping: 'Trading',
    subGrouping: 'Contract',
    categoryName_CN: '用户支付合约平仓手续费',
    categoryName: 'User pays contract closing fee',
    Costbasis: true,
    BorrowingCostbasis: false,
    ReceivalbeCBS: false,
    tokenDirection: '',
    fiatDirection: '/'
  },
  {
    categoryNo: '10204',
    primaryGrouping: 'Trading',
    subGrouping: 'Contract',
    categoryName_CN: '运营赠币（合约）',
    categoryName: 'Operational giveaway coins (contract)',
    Costbasis: true,
    BorrowingCostbasis: false,
    ReceivalbeCBS: false,
    tokenDirection: '',
    fiatDirection: '/'
  },
  {
    categoryNo: '10205',
    primaryGrouping: 'Trading',
    subGrouping: 'Contract',
    categoryName_CN: '向用户支付手续费分成',
    categoryName: 'Pay users a share of the transaction fees',
    Costbasis: true,
    BorrowingCostbasis: false,
    ReceivalbeCBS: false,
    tokenDirection: '',
    fiatDirection: '/'
  },
  {
    categoryNo: '10206',
    primaryGrouping: 'Trading',
    subGrouping: 'Contract',
    categoryName_CN: '穿仓用户分摊',
    categoryName: 'Shared by liquidation users',
    Costbasis: true,
    BorrowingCostbasis: false,
    ReceivalbeCBS: false,
    tokenDirection: '',
    fiatDirection: '/'
  },
  {
    categoryNo: '10207',
    primaryGrouping: 'Trading',
    subGrouping: 'Contract',
    categoryName_CN: '平账 (平账金额 > 0)',
    categoryName: 'Balance settlement (balance > 0)',
    Costbasis: true,
    BorrowingCostbasis: false,
    ReceivalbeCBS: false,
    tokenDirection: '',
    fiatDirection: '/'
  },
  {
    categoryNo: '10208',
    primaryGrouping: 'Trading',
    subGrouping: 'Contract',
    categoryName_CN: '平账 (平账金额 <= 0)',
    categoryName: 'Balance settlement (balance <= 0)',
    Costbasis: true,
    BorrowingCostbasis: false,
    ReceivalbeCBS: false,
    tokenDirection: '',
    fiatDirection: '/'
  },
  {
    categoryNo: '10209',
    primaryGrouping: 'Trading',
    subGrouping: 'Contract',
    categoryName_CN: '爆仓单盈利划入风险准备金',
    categoryName: 'Profit from liquidated orders transferred to risk reserve fund',
    Costbasis: true,
    BorrowingCostbasis: false,
    ReceivalbeCBS: false,
    tokenDirection: '',
    fiatDirection: '/'
  },
  {
    categoryNo: '10210',
    primaryGrouping: 'Trading',
    subGrouping: 'Contract',
    categoryName_CN: '定期结算多仓（全仓）',
    categoryName: 'Settlement of long positions in regular contracts (cross margin)',
    Costbasis: true,
    BorrowingCostbasis: false,
    ReceivalbeCBS: false,
    tokenDirection: '',
    fiatDirection: '/'
  },
  {
    categoryNo: '10211',
    primaryGrouping: 'Trading',
    subGrouping: 'Contract',
    categoryName_CN: '定期结算空仓（全仓）',
    categoryName: 'Settlement of short positions in regular contracts (cross margin)',
    Costbasis: true,
    BorrowingCostbasis: false,
    ReceivalbeCBS: false,
    tokenDirection: '',
    fiatDirection: '/'
  },
  {
    categoryNo: '10212',
    primaryGrouping: 'Trading',
    subGrouping: 'Contract',
    categoryName_CN: '定期结算多仓（逐仓）',
    categoryName: 'Settlement of long positions in regular contracts (isolated margin)',
    Costbasis: true,
    BorrowingCostbasis: false,
    ReceivalbeCBS: false,
    tokenDirection: '',
    fiatDirection: '/'
  },
  {
    categoryNo: '10213',
    primaryGrouping: 'Trading',
    subGrouping: 'Contract',
    categoryName_CN: '定期结算空仓（逐仓）',
    categoryName: 'Settlement of short positions in regular contracts (isolated margin)',
    Costbasis: true,
    BorrowingCostbasis: false,
    ReceivalbeCBS: false,
    tokenDirection: '',
    fiatDirection: '/'
  },
  {
    categoryNo: '10214',
    primaryGrouping: 'Trading',
    subGrouping: 'Contract',
    categoryName_CN: '向用户支付资金费用 (全仓)',
    categoryName: 'Pay funding cost to users (cross margin)',
    Costbasis: true,
    BorrowingCostbasis: false,
    ReceivalbeCBS: false,
    tokenDirection: '',
    fiatDirection: '/'
  },
  {
    categoryNo: '10215',
    primaryGrouping: 'Trading',
    subGrouping: 'Contract',
    categoryName_CN: '向用户支付资金费用 (逐仓)',
    categoryName: 'Pay funding cost to users (isolated margin)',
    Costbasis: true,
    BorrowingCostbasis: false,
    ReceivalbeCBS: false,
    tokenDirection: '',
    fiatDirection: '/'
  },
  {
    categoryNo: '10216',
    primaryGrouping: 'Trading',
    subGrouping: 'Contract',
    categoryName_CN: '用户支付资金费用 (全仓)',
    categoryName: 'Users pay funding cost(cross margin)',
    Costbasis: true,
    BorrowingCostbasis: false,
    ReceivalbeCBS: false,
    tokenDirection: '',
    fiatDirection: '/'
  },
  {
    categoryNo: '10217',
    primaryGrouping: 'Trading',
    subGrouping: 'Contract',
    categoryName_CN: '用户支付资金费用 (逐仓)',
    categoryName: 'Users pay funding cost(isolated margin)',
    Costbasis: true,
    BorrowingCostbasis: false,
    ReceivalbeCBS: false,
    tokenDirection: '',
    fiatDirection: '/'
  },
  {
    categoryNo: '10218',
    primaryGrouping: 'Trading',
    subGrouping: 'Contract',
    categoryName_CN: '用户支付合约平仓 maker 手续费（全仓）',
    categoryName: 'Users pay contract closing maker fee(cross margin)',
    Costbasis: true,
    BorrowingCostbasis: false,
    ReceivalbeCBS: false,
    tokenDirection: '',
    fiatDirection: '/'
  },
  {
    categoryNo: '10219',
    primaryGrouping: 'Trading',
    subGrouping: 'Contract',
    categoryName_CN: '用户支付合约平仓 maker 手续费（逐仓）',
    categoryName: 'Users pay contract closing maker fee(isolated margin)',
    Costbasis: true,
    BorrowingCostbasis: false,
    ReceivalbeCBS: false,
    tokenDirection: '',
    fiatDirection: '/'
  },
  {
    categoryNo: '10220',
    primaryGrouping: 'Trading',
    subGrouping: 'Contract',
    categoryName_CN: '合约交易其他收入(251)',
    categoryName: 'Other income from futures trading(251)',
    Costbasis: true,
    BorrowingCostbasis: false,
    ReceivalbeCBS: false,
    tokenDirection: '',
    fiatDirection: '/'
  },
  {
    categoryNo: '10221',
    primaryGrouping: 'Trading',
    subGrouping: 'Contract',
    categoryName_CN: '合约交易其他收入(252)',
    categoryName: 'Other income from futures trading(252)',
    Costbasis: true,
    BorrowingCostbasis: false,
    ReceivalbeCBS: false,
    tokenDirection: '',
    fiatDirection: '/'
  },
  {
    categoryNo: '10222',
    primaryGrouping: 'Trading',
    subGrouping: 'Contract',
    categoryName_CN: '合约交易其他收入(253)',
    categoryName: 'Other income from futures trading(253)',
    Costbasis: true,
    BorrowingCostbasis: false,
    ReceivalbeCBS: false,
    tokenDirection: '',
    fiatDirection: '/'
  },
  {
    categoryNo: '10223',
    primaryGrouping: 'Trading',
    subGrouping: 'Contract',
    categoryName_CN: '合约交易其他费用(251)',
    categoryName: 'Other expenses for futures trading(251)',
    Costbasis: true,
    BorrowingCostbasis: false,
    ReceivalbeCBS: false,
    tokenDirection: '',
    fiatDirection: '/'
  },
  {
    categoryNo: '10224',
    primaryGrouping: 'Trading',
    subGrouping: 'Contract',
    categoryName_CN: '合约交易其他费用(252)',
    categoryName: 'Other expenses for futures trading(252)',
    Costbasis: true,
    BorrowingCostbasis: false,
    ReceivalbeCBS: false,
    tokenDirection: '',
    fiatDirection: '/'
  },
  {
    categoryNo: '10225',
    primaryGrouping: 'Trading',
    subGrouping: 'Contract',
    categoryName_CN: '合约交易其他费用(253)',
    categoryName: 'Other expenses for futures trading(253)',
    Costbasis: true,
    BorrowingCostbasis: false,
    ReceivalbeCBS: false,
    tokenDirection: '',
    fiatDirection: '/'
  },
  {
    categoryNo: '10300',
    primaryGrouping: 'Trading',
    subGrouping: 'OTC',
    categoryName_CN: '用户支付场外交易手续费',
    categoryName: 'Users pay over-the-counter transaction fees',
    Costbasis: true,
    BorrowingCostbasis: false,
    ReceivalbeCBS: false,
    tokenDirection: '',
    fiatDirection: '/'
  },
  {
    categoryNo: '20000',
    primaryGrouping: 'Asset management',
    subGrouping: 'Financial management',
    categoryName_CN: '向用户支付理财利息',
    categoryName: 'Pay interest on financial management to users',
    Costbasis: true,
    BorrowingCostbasis: false,
    ReceivalbeCBS: false,
    tokenDirection: '',
    fiatDirection: '/'
  },
  {
    categoryNo: '20100',
    primaryGrouping: 'Asset management',
    subGrouping: 'Borrowing and lending',
    categoryName_CN: '用户抵押',
    categoryName: 'Collateralize user assets',
    Costbasis: false,
    BorrowingCostbasis: false,
    ReceivalbeCBS: false,
    tokenDirection: '',
    fiatDirection: '/'
  },
  {
    categoryNo: '20101',
    primaryGrouping: 'Asset management',
    subGrouping: 'Borrowing and lending',
    categoryName_CN: '用户借款',
    categoryName: 'Borrowing by user',
    Costbasis: true,
    BorrowingCostbasis: false,
    ReceivalbeCBS: true,
    tokenDirection: '',
    fiatDirection: '/'
  },
  {
    categoryNo: '20102',
    primaryGrouping: 'Asset management',
    subGrouping: 'Borrowing and lending',
    categoryName_CN: '用户补仓',
    categoryName: 'Supplement position by user',
    Costbasis: false,
    BorrowingCostbasis: false,
    ReceivalbeCBS: false,
    tokenDirection: '',
    fiatDirection: '/'
  },
  {
    categoryNo: '20103',
    primaryGrouping: 'Asset management',
    subGrouping: 'Borrowing and lending',
    categoryName_CN: '用户归还本金',
    categoryName: 'Repay principal by user',
    Costbasis: true,
    BorrowingCostbasis: false,
    ReceivalbeCBS: true,
    tokenDirection: '',
    fiatDirection: '/'
  },
  {
    categoryNo: '20104',
    primaryGrouping: 'Asset management',
    subGrouping: 'Borrowing and lending',
    categoryName_CN: '用户支付借贷利息',
    categoryName: 'Pay loan interest and fees by user',
    Costbasis: true,
    BorrowingCostbasis: false,
    ReceivalbeCBS: false,
    tokenDirection: '',
    fiatDirection: '/'
  },
  {
    categoryNo: '20105',
    primaryGrouping: 'Asset management',
    subGrouping: 'Borrowing and lending',
    categoryName_CN: '用户支付借贷手续费',
    categoryName: 'User pays loan processing fee',
    Costbasis: true,
    BorrowingCostbasis: false,
    ReceivalbeCBS: false,
    tokenDirection: '',
    fiatDirection: '/'
  },
  {
    categoryNo: '20106',
    primaryGrouping: 'Asset management',
    subGrouping: 'Borrowing and lending',
    categoryName_CN: '公司支付爆仓平仓费用',
    categoryName: 'Company bears liquidation closure costs',
    Costbasis: true,
    BorrowingCostbasis: false,
    ReceivalbeCBS: false,
    tokenDirection: '',
    fiatDirection: '/'
  },
  {
    categoryNo: '20107',
    primaryGrouping: 'Asset management',
    subGrouping: 'Borrowing and lending',
    categoryName_CN: '公司归还抵押物',
    categoryName: 'Company returns collateral',
    Costbasis: false,
    BorrowingCostbasis: false,
    ReceivalbeCBS: false,
    tokenDirection: '',
    fiatDirection: '/'
  },
  {
    categoryNo: '30001',
    primaryGrouping: 'Value-added services',
    subGrouping: 'Innovation pilot zone',
    categoryName_CN: '募集资金划账',
    categoryName: 'Transfer raised funds',
    Costbasis: true,
    BorrowingCostbasis: true,
    ReceivalbeCBS: false,
    tokenDirection: '',
    fiatDirection: '/'
  },
  {
    categoryNo: '30002',
    primaryGrouping: 'Value-added services',
    subGrouping: 'Innovation pilot zone',
    categoryName_CN: '发币至用户可用账户',
    categoryName: "Issue coins to user's available account",
    Costbasis: true,
    BorrowingCostbasis: true,
    ReceivalbeCBS: false,
    tokenDirection: '',
    fiatDirection: '/'
  },
  {
    categoryNo: '30003',
    primaryGrouping: 'Value-added services',
    subGrouping: 'Innovation pilot zone',
    categoryName_CN: '发币至用户创新实验区账户',
    categoryName: "Issue coins to user's innovation experimental area account",
    Costbasis: true,
    BorrowingCostbasis: true,
    ReceivalbeCBS: false,
    tokenDirection: '',
    fiatDirection: '/'
  },
  {
    categoryNo: '30004',
    primaryGrouping: 'Value-added services',
    subGrouping: 'Innovation pilot zone',
    categoryName_CN: '发币至用户锁仓账户',
    categoryName: 'Issue coins to locked account of the user',
    Costbasis: true,
    BorrowingCostbasis: true,
    ReceivalbeCBS: false,
    tokenDirection: '',
    fiatDirection: '/'
  },
  {
    categoryNo: '30100',
    primaryGrouping: 'Value-added services',
    subGrouping: 'Operating',
    categoryName_CN: '经纪人邀请返还',
    categoryName: 'Broker invitation rebate',
    Costbasis: true,
    BorrowingCostbasis: false,
    ReceivalbeCBS: false,
    tokenDirection: '',
    fiatDirection: '/'
  },
  {
    categoryNo: '30101',
    primaryGrouping: 'Value-added services',
    subGrouping: 'Operating',
    categoryName_CN: '手续费分红',
    categoryName: 'Fee dividend',
    Costbasis: true,
    BorrowingCostbasis: false,
    ReceivalbeCBS: false,
    tokenDirection: '',
    fiatDirection: '/'
  },
  {
    categoryNo: '30102',
    primaryGrouping: 'Value-added services',
    subGrouping: 'Operating',
    categoryName_CN: '运营赠币',
    categoryName: 'Operational giveaway coins',
    Costbasis: true,
    BorrowingCostbasis: false,
    ReceivalbeCBS: false,
    tokenDirection: '',
    fiatDirection: '/'
  },
  {
    categoryNo: '30103',
    primaryGrouping: 'Value-added services',
    subGrouping: 'Operating',
    categoryName_CN: '系统锁仓赠币支出',
    categoryName: 'System lock-up coin expenditure',
    Costbasis: true,
    BorrowingCostbasis: false,
    ReceivalbeCBS: false,
    tokenDirection: '',
    fiatDirection: '/'
  },
  {
    categoryNo: '30200',
    primaryGrouping: 'Value-added services',
    subGrouping: 'Adjusting',
    categoryName_CN: '杠杆调账收入',
    categoryName: 'Leverage adjustment income',
    Costbasis: true,
    BorrowingCostbasis: false,
    ReceivalbeCBS: false,
    tokenDirection: '',
    fiatDirection: '/'
  },
  {
    categoryNo: '30201',
    primaryGrouping: 'Value-added services',
    subGrouping: 'Adjusting',
    categoryName_CN: '杠杆调账费用',
    categoryName: 'Leverage adjustment fee',
    Costbasis: true,
    BorrowingCostbasis: false,
    ReceivalbeCBS: false,
    tokenDirection: '',
    fiatDirection: '/'
  },
  {
    categoryNo: '40000',
    primaryGrouping: 'Basic',
    subGrouping: 'Deposits',
    categoryName_CN: '用户充值',
    categoryName: 'User deposit',
    Costbasis: true,
    BorrowingCostbasis: true,
    ReceivalbeCBS: false,
    tokenDirection: '',
    fiatDirection: '/'
  },
  {
    categoryNo: '40001',
    primaryGrouping: 'Basic',
    subGrouping: 'Deposits',
    categoryName_CN: '商户管理员充值',
    categoryName: 'Merchant administrator deposit',
    Costbasis: true,
    BorrowingCostbasis: false,
    ReceivalbeCBS: false,
    tokenDirection: '',
    fiatDirection: '/'
  },
  {
    categoryNo: '40100',
    primaryGrouping: 'Basic',
    subGrouping: 'Withdrawal',
    categoryName_CN: '用户提现成功',
    categoryName: 'Successful user withdrawal',
    Costbasis: true,
    BorrowingCostbasis: true,
    ReceivalbeCBS: false,
    tokenDirection: '',
    fiatDirection: '/'
  },
  {
    categoryNo: '40200',
    primaryGrouping: 'Basic',
    subGrouping: 'Withdrawal fee',
    categoryName_CN: '用户支付出金手续费',
    categoryName: 'User pays withdrawal fees',
    Costbasis: true,
    BorrowingCostbasis: false,
    ReceivalbeCBS: false,
    tokenDirection: '',
    fiatDirection: '/'
  },
  {
    categoryNo: '40201',
    primaryGrouping: 'Basic',
    subGrouping: 'Withdrawal fee',
    categoryName_CN: '用户支付直转出金手续费',
    categoryName: 'User pays direct transfer out fees',
    Costbasis: true,
    BorrowingCostbasis: false,
    ReceivalbeCBS: false,
    tokenDirection: '',
    fiatDirection: '/'
  },
  {
    categoryNo: '80000',
    primaryGrouping: 'Internal transfer',
    subGrouping: 'Internal transfer',
    categoryName_CN: '内部转账',
    categoryName: 'Internal transfer',
    Costbasis: false,
    BorrowingCostbasis: false,
    ReceivalbeCBS: false,
    tokenDirection: '',
    fiatDirection: '/'
  },
  {
    categoryNo: '99994',
    primaryGrouping: 'Gain/Loss adjusting',
    subGrouping: 'Unrealized gain/loss',
    categoryName_CN: '未实现损益调账-资产',
    categoryName: 'Unrealized profit and loss adjustment - assets',
    Costbasis: false,
    BorrowingCostbasis: false,
    ReceivalbeCBS: false,
    tokenDirection: '/',
    fiatDirection: '/'
  },
  {
    categoryNo: '99995',
    primaryGrouping: 'Gain/Loss adjusting',
    subGrouping: 'Realized gain/loss',
    categoryName_CN: '已实现损益调账-资产',
    categoryName: 'Realized profit and loss adjustment - assets',
    Costbasis: false,
    BorrowingCostbasis: false,
    ReceivalbeCBS: false,
    tokenDirection: '/',
    fiatDirection: '/'
  },
  {
    categoryNo: '99996',
    primaryGrouping: 'Gain/Loss adjusting',
    subGrouping: 'Unrealized gain/loss',
    categoryName_CN: '未实现损益调账-负债',
    categoryName: 'Unrealized profit and loss adjustment - liabilities',
    Costbasis: false,
    BorrowingCostbasis: false,
    ReceivalbeCBS: false,
    tokenDirection: '/',
    fiatDirection: '/'
  },
  {
    categoryNo: '99997',
    primaryGrouping: 'Gain/Loss adjusting',
    subGrouping: 'Realized gain/loss',
    categoryName_CN: '已实现损益调账-负债',
    categoryName: 'Realized profit and loss adjustment - liabilities',
    Costbasis: false,
    BorrowingCostbasis: false,
    ReceivalbeCBS: false,
    tokenDirection: '/',
    fiatDirection: '/'
  }
]

// 所有科目列表
export const exportAccountList = [
  {
    accountId: '100',
    accountName: 'Assets'
  },
  {
    accountId: '100-00',
    accountName: 'Cash and cash equivalents'
  },
  {
    accountId: '100-01',
    accountName: 'Crypto assets'
  },
  {
    accountId: '100-01-00',
    accountName: 'Unrestricted crypto assets'
  },
  {
    accountId: '100-01-01',
    accountName: 'Restricted crypto assets'
  },
  {
    accountId: '100-01-01-00',
    accountName: 'Crypto assets restricted by staking protocols'
  },
  {
    accountId: '100-01-01-01',
    accountName: 'Crypto assets restricted by liquidity providing protoclos'
  },
  {
    accountId: '100-01-01-02',
    accountName: 'Crypto assets restricted by farming protocols'
  },
  {
    accountId: '100-01-01-03',
    accountName: 'Crypto assets restricted by lender'
  },
  {
    accountId: '100-01-01-04',
    accountName: 'Crypto assets restricted by custodians'
  },
  {
    accountId: '100-01-01-05',
    accountName: 'Crypto assets restricted by locking protocols'
  },
  {
    accountId: '100-02',
    accountName: 'Receivables'
  },
  {
    accountId: '100-01-02',
    accountName: 'Customer crypto assets'
  },
  {
    accountId: '100-02-01',
    accountName: 'Crypto receivables'
  },
  {
    accountId: '100-02-01-00',
    accountName: 'Staking rewards receivalbe'
  },
  {
    accountId: '100-02-01-01',
    accountName: 'LP staking rewards receivalbe'
  },
  {
    accountId: '100-02-01-02',
    accountName: 'Farming rewards receivalbe'
  },
  {
    accountId: '100-02-01-03',
    accountName: 'Locking rewards receivalbe'
  },
  {
    accountId: '100-02-01-04',
    accountName: 'Interest receivable'
  },
  {
    accountId: '100-02-01-05',
    accountName: 'Dividends receivable'
  },
  {
    accountId: '100-02-01-06',
    accountName: 'Loan receivable'
  },
  {
    accountId: '100-02-01-06',
    accountName: 'Accounts receivalbe'
  },
  {
    accountId: '100-02-99',
    accountName: 'Other receivables'
  },
  {
    accountId: '100-03',
    accountName: 'Inventory'
  },
  {
    accountId: '100-04',
    accountName: 'Prepaid expenses'
  },
  {
    accountId: '100-05',
    accountName: 'Investments'
  },
  {
    accountId: '100-05-00',
    accountName: 'Pre-ICOs'
  },
  {
    accountId: '100-05-02',
    accountName: 'Long-term Investments'
  },
  {
    accountId: '100-06',
    accountName: 'Property, plant and equipment'
  },
  {
    accountId: '100-07',
    accountName: 'NFTs, net'
  },
  {
    accountId: '100-07-00',
    accountName: 'NFTs'
  },
  {
    accountId: '100-07-01',
    accountName: 'Accumulated impairment - NFTs'
  },
  {
    accountId: '200',
    accountName: 'Liabilities'
  },
  {
    accountId: '200-00',
    accountName: 'Payables'
  },
  {
    accountId: '200-00-00',
    accountName: 'Crypto payables'
  },
  {
    accountId: '200-00-00-00',
    accountName: 'Accounts payable'
  },
  {
    accountId: '200-00-00-01',
    accountName: 'Dividends payable'
  },
  {
    accountId: '200-00-00-02',
    accountName: 'Interest payable'
  },
  {
    accountId: '200-00-99',
    accountName: 'Other payables'
  },
  {
    accountId: '200-01',
    accountName: 'Accrued expenses'
  },
  {
    accountId: '200-02',
    accountName: 'Financial liabilities'
  },
  {
    accountId: '200-02-00',
    accountName: 'Crypto borrowings'
  },
  {
    accountId: '200-02-01',
    accountName: 'Deposit from customers'
  },
  {
    accountId: '200-02-02',
    accountName: 'Customer crypto liabilities'
  },
  {
    accountId: '200-02-99',
    accountName: 'Other debts and borrowings'
  },
  {
    accountId: '300',
    accountName: 'Equity'
  },
  {
    accountId: '300-00',
    accountName: "Owner's equity "
  },
  {
    accountId: '300-01',
    accountName: 'Retained earnings'
  },
  {
    accountId: '400',
    accountName: 'Revenue'
  },
  {
    accountId: '400-00',
    accountName: 'Operating income'
  },
  {
    accountId: '400-00-00',
    accountName: 'NFTs primary sales'
  },
  {
    accountId: '400-00-01',
    accountName: 'NFTs secondary sales'
  },
  {
    accountId: '400-00-02',
    accountName: 'NFTs royalties'
  },
  {
    accountId: '400-00-03',
    accountName: 'Commissions income'
  },
  {
    accountId: '400-00-04',
    accountName: 'DAO incentive income'
  },
  {
    accountId: '400-00-05',
    accountName: 'Sponsorship income'
  },
  {
    accountId: '400-00-06',
    accountName: 'Mint income'
  },
  {
    accountId: '400-00-07',
    accountName: 'Interest income'
  },
  {
    accountId: '400-00-99',
    accountName: 'Other operating income'
  },
  {
    accountId: '400-01-00',
    accountName: 'Net PnL from traders'
  },
  {
    accountId: '400-01-01',
    accountName: 'Net unrealized PnL from traders'
  },
  {
    accountId: '500',
    accountName: 'Expenses'
  },
  {
    accountId: '500-00',
    accountName: 'Cost of sales'
  },
  {
    accountId: '500-00-00',
    accountName: 'Cost of NFTs'
  },
  {
    accountId: '500-00-00-00',
    accountName: 'Cost of NFTs'
  },
  {
    accountId: '500-00-00-01',
    accountName: 'Cost of NFTs - royalties'
  },
  {
    accountId: '500-00-99',
    accountName: 'Other cost of sales'
  },
  {
    accountId: '500-01',
    accountName: 'General and administrative expenses'
  },
  {
    accountId: '500-01-00',
    accountName: 'Payroll and staff related costs'
  },
  {
    accountId: '500-01-01',
    accountName: 'Research and development'
  },
  {
    accountId: '500-01-02',
    accountName: 'Software & web services'
  },
  {
    accountId: '500-01-99',
    accountName: 'Miscellaneous operating expenses'
  },
  {
    accountId: '500-02',
    accountName: 'Marketing expense'
  },
  {
    accountId: '500-03',
    accountName: 'Transaction cost'
  },
  {
    accountId: '500-04',
    accountName: 'Impairment cost'
  },
  {
    accountId: '500-04-00',
    accountName: 'Impairment cost - NFTs'
  },
  {
    accountId: '500-04-01',
    accountName: 'Impairment cost - Long-term investments'
  },
  {
    accountId: '500-05',
    accountName: 'Royalties cost'
  },
  {
    accountId: '500-06',
    accountName: 'Commission fee'
  },
  {
    accountId: '500-07',
    accountName: 'Interest expenses'
  },
  {
    accountId: '600',
    accountName: 'Other income and expenses'
  },
  {
    accountId: '600-00',
    accountName: 'Other income'
  },
  {
    accountId: '600-00-00',
    accountName: 'Financial income'
  },
  {
    accountId: '600-00-00-00',
    accountName: 'Interest income'
  },
  {
    accountId: '600-00-00-01',
    accountName: 'Dividends income'
  },
  {
    accountId: '600-00-00-02',
    accountName: 'Staking rewards'
  },
  {
    accountId: '600-00-00-03',
    accountName: 'LP staking rewards'
  },
  {
    accountId: '600-00-00-04',
    accountName: 'Farming rewards'
  },
  {
    accountId: '600-00-00-05',
    accountName: 'Custody income'
  },
  {
    accountId: '600-00-00-06',
    accountName: 'Locking rewards'
  },
  {
    accountId: '600-00-00-99',
    accountName: 'Other investment income'
  },
  {
    accountId: '600-00-01',
    accountName: 'Other income'
  },
  {
    accountId: '600-00-01-00',
    accountName: 'Airdrop'
  },
  {
    accountId: '600-00-01-01',
    accountName: 'Hard fork'
  },
  {
    accountId: '600-00-01-02',
    accountName: 'Chain split'
  },
  {
    accountId: '600-00-01-03',
    accountName: 'Grant income'
  },
  {
    accountId: '600-00-99',
    accountName: 'Other miscellaneous income'
  },
  {
    accountId: '600-01',
    accountName: 'Other expenses'
  },
  {
    accountId: '600-01-00',
    accountName: 'Financial expenses'
  },
  {
    accountId: '600-01-00-00',
    accountName: 'Interest expense'
  },
  {
    accountId: '600-01-00-02',
    accountName: 'hacked'
  },
  {
    accountId: '600-01-00-99',
    accountName: 'Other finance expenses'
  },
  {
    accountId: '600-01-01',
    accountName: 'lost/stolen'
  },
  {
    accountId: '600-01-99',
    accountName: 'Other miscellaneous expenses'
  },
  {
    accountId: '600-02',
    accountName: 'Gains And Losses'
  },
  {
    accountId: '600-02-00',
    accountName: 'Gain (Loss) on crypto activities'
  },
  {
    accountId: '600-02-01',
    accountName: 'Fiat exchange gain (loss)'
  },
  {
    accountId: '600-02-02',
    accountName: 'Other gains (Losses) '
  },
  {
    accountId: '600-02-03',
    accountName: 'Unrealized gain (loss) - crypto fair value measurement'
  },
  {
    accountId: '600-02-04',
    accountName: 'Other unrealized gains and (losses)'
  },
  {
    accountId: '600-02-05',
    accountName: 'Gain (Loss) on investments'
  }
]

// 报表字段列表
export const processTableFieldList: any = {
  holdings: {
    fieldGroupList: [
      {
        fieldId: 'holdings-assets',
        name: 'Assets',
        type: 'Currency'
      },
      {
        fieldId: 'holdings-quantity',
        name: 'Quantity',
        type: 'Number'
      },
      {
        fieldId: 'holdings-const-basis',
        name: 'Cost Basis',
        type: 'Number'
      },
      {
        fieldId: 'holdings-fair-value',
        name: 'Fair Value',
        type: 'Number'
      }
    ]
  },
  generalLedger: {
    fieldGroupList: [
      {
        fieldId: 'general-ledger-date-time',
        name: 'Datetime',
        type: 'DateTime'
      },
      {
        fieldId: 'general-ledger-event',
        name: 'Event',
        type: 'Event'
      },
      {
        fieldId: 'general-ledger-currency',
        name: 'Currency',
        type: 'Currency'
      },
      // {
      //   fieldId: 'general-ledger-opening-balance',
      //   name: 'Opening Balance',
      //   type: 'Balance'
      // },
      {
        fieldId: 'general-ledger-debit',
        name: 'Debit',
        type: 'Borrow'
      },
      {
        fieldId: 'general-ledger-credit',
        name: 'Credit',
        type: 'Borrow'
      },
      // {
      //   fieldId: 'general-ledger-closing-balance',
      //   name: 'Closing Balance',
      //   type: 'Balance'
      // },
      {
        fieldId: 'general-ledger-from-to',
        name: 'From/To',
        type: 'AddressFromTo'
      }
    ]
  },
  transactions: {
    fieldGroupList: [
      {
        fieldId: 'transactions-date-time',
        name: 'Datetime',
        type: 'DateTime'
      },
      {
        fieldId: 'transactions-source',
        name: 'Source',
        type: 'Currency'
      },
      {
        fieldId: 'transactions-currency',
        name: 'Currency',
        type: 'Currency'
      },
      {
        fieldId: 'transactions-amount-in',
        name: 'Amount (In)',
        type: 'Borrow'
      },
      {
        fieldId: 'transactions-amount-out',
        name: 'Amount (Out)',
        type: 'Borrow'
      },
      {
        fieldId: 'transactions-from-to',
        name: 'From/To',
        type: 'FromTo'
      },
      {
        fieldId: 'transactions-event',
        name: 'category',
        type: 'Event'
      }
    ]
  },
  accounts: {
    fieldGroupList: [
      {
        fieldId: 'accounts-name',
        name: 'Name',
        type: 'Text'
      },
      {
        fieldId: 'accounts-address',
        name: 'Address/Bank Account',
        type: 'Address'
      },
      {
        fieldId: 'accounts-memo',
        name: 'Memo',
        type: 'Text'
      }
    ]
  },
  contactsNamed: {
    fieldGroupList: [
      {
        fieldId: 'contacts-name',
        name: 'Name',
        type: 'Text'
      },
      {
        fieldId: 'contacts-address',
        name: 'Address/Bank Account',
        type: 'Address'
      },
      {
        fieldId: 'contacts-memo',
        name: 'Memo',
        type: 'Text'
      }
    ]
  },
  contactsUnnamed: {
    fieldGroupList: [
      {
        fieldId: 'contacts-address',
        name: 'Address/Bank Account',
        type: 'Address'
      },
      {
        fieldId: 'contacts-transactions',
        name: 'Transactions',
        type: 'Number'
      }
    ]
  }
}

// general ledger字段列表
export const generalLedgerFieldList: any = [
  { accountId: '100-00', accountName: 'Cash and cash equivalents' },
  { accountId: '100-01-00', accountName: 'Unrestricted crypto assets' },
  { accountId: '200-02-00', accountName: 'Crypto borrowings' },
  { accountId: '200-02-01', accountName: 'Deposit from customers' },
  { accountId: '400-00-00', accountName: 'NFTs primary sales' },
  { accountId: '400-00-01', accountName: 'NFTs secondary sales' },
  { accountId: '400-00-02', accountName: 'NFTs royalties' },
  { accountId: '400-00-03', accountName: 'Commissions income' },
  { accountId: '400-00-04', accountName: 'DAO incentives' },
  { accountId: '400-00-05', accountName: 'Sponsorship revenue' },
  { accountId: '400-00-99', accountName: 'Other operating income' },
  { accountId: '500-01-00', accountName: 'Payroll and staff related costs' },
  { accountId: '500-01-01', accountName: 'Research and development' },
  { accountId: '500-01-02', accountName: 'Software & web services' },
  { accountId: '500-01-99', accountName: 'Miscellaneous operating expenses' },
  { accountId: '500-02', accountName: 'Marketing expense' },
  { accountId: '500-03', accountName: 'Transaction cost' },
  { accountId: '500-04', accountName: 'Impairments - NFTs' },
  { accountId: '600-00-00-00', accountName: 'Interest income' },
  { accountId: '600-00-00-01', accountName: 'Dividends income' },
  { accountId: '600-00-00-02', accountName: 'Staking rewards' },
  { accountId: '600-00-00-03', accountName: 'LP staking rewards' },
  { accountId: '600-00-00-04', accountName: 'Farming rewards' },
  { accountId: '600-00-00-99', accountName: 'Other investment income' },
  { accountId: '600-01-01', accountName: 'Lost/stolen' },
  { accountId: '600-01-99', accountName: 'Other miscellaneous expenses' },
  { accountId: '600-02-01', accountName: 'Fiat exchange gain (loss)' },
  { accountId: '100-01-00', accountName: 'Unrestricted crypto assets ' },
  { accountId: '100-01-01-00', accountName: 'Crypto assets restricted by staking protocols' },
  { accountId: '100-01-01-01', accountName: 'Crypto assets restricted by liquidity providing protoclos' },
  { accountId: '100-01-01-02', accountName: 'Crypto assets restricted by farming protocols' },
  { accountId: '100-01-01-03', accountName: 'Crypto assets restricted by lender' },
  { accountId: '100-01-01-04', accountName: 'Crypto assets restricted by custodians' },
  { accountId: '100-01-01-05', accountName: 'Crypto assets restricted by locking protocols' }
]

// cash-flow-statement字段列表
export const cashFlowStatementFieldList: any = [
  {
    fieldId: 'cashflow-100',
    name: 'Cash flows from operating activities',
    type: 'ACCOUNT',
    balanceType: 'Dr',
    config: {
      level: 1,
      filter: false
    },
    description: null,
    whetherExpanded: false,
    popover: false
  },
  {
    fieldId: 'cashflow-100-0',
    name: 'Sales of goods or services',
    type: 'ACCOUNT',
    balanceType: 'Dr',
    config: {
      level: 2,
      operator: '+',
      filter: false
    },
    description: null,
    whetherExpanded: true, // 法币
    popover: false
  },
  {
    fieldId: 'cashflow-100-1',
    name: 'Payments to suppliers',
    type: 'ACCOUNT',
    balanceType: 'Dr',
    config: {
      level: 2,
      operator: '-',
      filter: true
    },
    description: null,
    whetherExpanded: true, // 法币
    popover: false
  },
  {
    fieldId: 'cashflow-100-2',
    name: 'Payments of general and administrative expenses',
    type: 'ACCOUNT',
    balanceType: 'Dr',
    config: {
      level: 2,
      operator: '-',
      filter: true
    },
    description: null,
    whetherExpanded: true, // 法币
    popover: false
  },
  {
    fieldId: 'cashflow-100-3',
    name: 'Payments of  expenses',
    type: 'ACCOUNT',
    balanceType: 'Dr',
    config: {
      level: 2,
      operator: '-',
      filter: true
    },
    description: null,
    whetherExpanded: true, // 法币
    popover: false
  },
  {
    fieldId: 'cashflow-100-4',
    name: 'Other operating cash receipts and payments',
    type: 'ACCOUNT',
    balanceType: 'Dr',
    config: {
      level: 2,
      operator: '+',
      filter: false
    },
    description: null,
    whetherExpanded: true, // 法币
    popover: false
  },
  {
    fieldId: 'cashflow-100-agg',
    name: 'Net cash from operating activities',
    type: 'AGGREGATION',
    balanceType: 'Dr',
    config: {
      operator: '+',
      filter: false,
      formula: [
        'cashflow-100-0',
        'cashflow-100-1',
        'cashflow-100-2',
        'cashflow-100-3',
        'cashflow-100-4',
        'cashflow-100-agg'
      ]
    },
    description: null,
    whetherExpanded: false,
    popover: true // 计算公式弹窗
  },
  {
    fieldId: 'cashflow-200',
    name: 'Cash flows from financing activities',
    type: 'ACCOUNT',
    balanceType: 'Dr',
    config: {
      level: 1,
      filter: false
    },
    description: null,
    whetherExpanded: false,
    popover: false
  },
  {
    fieldId: 'cashflow-200-0',
    name: 'Proceeds from issuance of debt',
    type: 'ACCOUNT',
    balanceType: 'Dr',
    config: {
      level: 2,
      operator: '+',
      filter: false
    },
    description: null,
    whetherExpanded: true, // 法币
    popover: false
  },
  {
    fieldId: 'cashflow-200-1',
    name: 'Repayments of debt',
    type: 'ACCOUNT',
    balanceType: 'Dr',
    config: {
      level: 2,
      operator: '-',
      filter: true
    },
    description: null,
    whetherExpanded: true, // 法币
    popover: false
  },
  {
    fieldId: 'cashflow-200-2',
    name: 'Issuance of common stock',
    type: 'ACCOUNT',
    balanceType: 'Dr',
    config: {
      level: 2,
      operator: '+',
      filter: false
    },
    description: null,
    whetherExpanded: true, // 法币
    popover: false
  },
  {
    fieldId: 'cashflow-200-3',
    name: 'Payment of dividends',
    type: 'ACCOUNT',
    balanceType: 'Dr',
    config: {
      level: 2,
      operator: '-',
      filter: true
    },
    description: null,
    whetherExpanded: true, // 法币
    popover: false
  },
  {
    fieldId: 'cashflow-200-agg',
    name: 'Net cash from financing activities',
    type: 'AGGREGATION',
    balanceType: 'Dr',
    config: {
      operator: '+',
      filter: false,
      formula: ['cashflow-200-0', 'cashflow-200-1', 'cashflow-200-2', 'cashflow-200-3', 'cashflow-200-agg']
    },
    description: null,
    whetherExpanded: false,
    popover: true // 计算公式弹窗
  },
  {
    fieldId: 'cashflow-300',
    name: 'Cash flows from investing activities',
    type: 'ACCOUNT',
    balanceType: 'Dr',
    config: {
      level: 1,
      filter: false
    },
    description: null,
    whetherExpanded: false,
    popover: false
  },
  {
    fieldId: 'cashflow-300-0',
    name: 'Proceeds from disposal of crypto assets',
    type: 'ACCOUNT',
    balanceType: 'Dr',
    config: {
      level: 2,
      operator: '+',
      filter: false
    },
    description: null,
    whetherExpanded: true, // 法币
    popover: false
  },
  {
    fieldId: 'cashflow-300-1',
    name: 'Purchases of crypto assets',
    type: 'ACCOUNT',
    balanceType: 'Dr',
    config: {
      level: 2,
      operator: '-',
      filter: false
    },
    description: null,
    whetherExpanded: true, // 法币
    popover: false
  },
  {
    fieldId: 'cashflow-300-2',
    name: 'Purchases of investments',
    type: 'ACCOUNT',
    balanceType: 'Dr',
    config: {
      level: 2,
      operator: '-',
      filter: true
    },
    description: null,
    whetherExpanded: true, // 法币
    popover: false
  },
  {
    fieldId: 'cashflow-300-3',
    name: 'Proceeds from settlement of investments',
    type: 'ACCOUNT',
    balanceType: 'Dr',
    config: {
      level: 2,
      operator: '+',
      filter: true
    },
    description: null,
    whetherExpanded: true, // 法币
    popover: false
  },
  {
    fieldId: 'cashflow-300-4',
    name: 'Receive investment income',
    type: 'ACCOUNT',
    balanceType: 'Dr',
    config: {
      level: 2,
      operator: '+',
      filter: true
    },
    description: null,
    whetherExpanded: true, // 法币
    popover: false
  },
  {
    fieldId: 'cashflow-300-5',
    name: 'Exchange rate fluctuation',
    type: 'ACCOUNT',
    balanceType: 'Dr',
    config: {
      level: 2,
      operator: '+', // 金额为正时符号位“+”，金额为负时符号为“-”
      filter: true
    },
    description: null,
    whetherExpanded: true, // 法币
    popover: false
  },
  {
    fieldId: 'cashflow-300-agg',
    name: 'Net cash from financing activities',
    type: 'AGGREGATION',
    balanceType: 'Dr',
    config: {
      operator: '+',
      filter: false,
      formula: [
        'cashflow-300-0',
        'cashflow-300-1',
        'cashflow-300-2',
        'cashflow-300-3',
        'cashflow-300-4',
        'cashflow-300-5',
        'cashflow-300-agg'
      ]
    },
    description: null,
    whetherExpanded: false,
    popover: true // 计算公式弹窗
  },
  {
    fieldId: 'cashflow-all-agg',
    name: 'Net (decrease) increase in cash and cash equivalents',
    type: 'AGGREGATION',
    balanceType: 'Dr',
    config: {
      operator: '+',
      filter: false,
      hideBottomLine: true,
      formula: ['cashflow-100-agg', 'cashflow-200-agg', 'cashflow-300-agg', 'cashflow-all-agg']
    },
    description: null,
    whetherExpanded: false,
    popover: true // 计算公式弹窗
  },
  {
    fieldId: 'cashflow-begin',
    name: 'Cash and cash equivalents at the beginning of period',
    type: 'AGGREGATION',
    balanceType: 'Dr',
    config: {
      operator: '+',
      filter: false,
      hideTopLine: true
    },
    description: null,
    whetherExpanded: false,
    popover: false
  },
  {
    fieldId: 'cashflow-end',
    name: 'Cash and cash equivalents at the end of period',
    type: 'AGGREGATION',
    balanceType: 'Dr',
    config: {
      filter: false,
      formula: ['cashflow-all-agg', 'cashflow-begin', 'cashflow-end']
    },
    description: null,
    whetherExpanded: false,
    popover: true // 计算公式弹窗
  }
]

// restrictions-crypto-assets字段列表
export const restrictionsCryptoAssetsFieldList: any = [
  {
    fieldId: '111100-01-00',
    name: 'Unrestricted crypto assets',
    type: 'ACCOUNT',
    balanceType: 'Dr',
    config: {
      level: 1,
      filter: false
    },
    description: null,
    whetherExpanded: false,
    popover: false
  },
  {
    fieldId: '100-01-00',
    name: 'Unrestricted crypto assets',
    type: 'ACCOUNT',
    balanceType: 'Dr',
    config: {
      level: 2,
      filter: false
    },
    description: null,
    whetherExpanded: true, // 加密货币
    popover: false
  },
  {
    fieldId: '100-01-01123123123',
    name: 'Restricted crypto assets',
    type: 'ACCOUNT',
    balanceType: 'Dr',
    config: {
      level: 1,
      filter: false
    },
    description: null,
    whetherExpanded: false,
    popover: false
  },
  {
    fieldId: '100-01-01-00',
    name: 'Crypto assets restricted by staking protocols',
    type: 'ACCOUNT',
    balanceType: 'Dr',
    config: {
      level: 2,
      filter: false
    },
    description: null,
    whetherExpanded: true, // 加密货币
    popover: false
  },
  {
    fieldId: '100-01-01-01',
    name: 'Crypto assets restricted by liquidity providing protoclos',
    type: 'ACCOUNT',
    balanceType: 'Dr',
    config: {
      level: 2,
      filter: false
    },
    description: null,
    whetherExpanded: true, // 加密货币
    popover: false
  },
  {
    fieldId: '100-01-01-02',
    name: 'Crypto assets restricted by farming protocols',
    type: 'ACCOUNT',
    balanceType: 'Dr',
    config: {
      level: 2,
      filter: false
    },
    description: null,
    whetherExpanded: true, // 加密货币
    popover: false
  },
  {
    fieldId: '100-01-01-03',
    name: 'Crypto assets restricted by lender',
    type: 'ACCOUNT',
    balanceType: 'Dr',
    config: {
      level: 2,
      filter: false
    },
    description: null,
    whetherExpanded: true, // 加密货币
    popover: false
  },
  {
    fieldId: '100-01-01-04',
    name: 'Crypto assets restricted by custodians',
    type: 'ACCOUNT',
    balanceType: 'Dr',
    config: {
      level: 2,
      filter: false
    },
    description: null,
    whetherExpanded: true, // 加密货币
    popover: false
  },
  {
    fieldId: '100-01-01-05',
    name: 'Crypto assets restricted by locking protocols',
    type: 'ACCOUNT',
    balanceType: 'Dr',
    config: {
      level: 2,
      filter: false
    },
    description: null,
    whetherExpanded: true, // 加密货币
    popover: false
  },
  {
    fieldId: '100-01-01',
    name: 'Total restricted crypto assets',
    type: 'AGGREGATION',
    balanceType: 'Dr',
    config: {
      filter: false
    },
    description: null,
    whetherExpanded: false,
    popover: false
  }
]

// roll-forward字段列表
export const rollForwardFieldList: any = [
  {
    fieldId: 'rollforward-00000',
    name: 'Assets',
    type: 'ACCOUNT',
    balanceType: 'Dr',
    config: {
      level: 1,
      filter: false,
      showBottomBorder: true
    },
    description: null,
    whetherExpanded: false,
    popover: false
  },
  {
    fieldId: 'rollforward-begin',
    name: 'Total fair value at the beginning of period',
    type: 'AGGREGATION',
    balanceType: 'Dr',
    config: {
      filter: false
    },
    description: null,
    whetherExpanded: false,
    popover: false
  },
  {
    fieldId: 'rollforward-100',
    name: 'Assets acquired',
    type: 'ACCOUNT',
    balanceType: 'Dr',
    config: {
      level: 1,
      filter: false
    },
    description: null,
    whetherExpanded: true, // 加密货币
    popover: false
  },
  {
    fieldId: 'rollforward-100-0',
    name: 'Purchases of crypto assets',
    type: 'ACCOUNT',
    balanceType: 'Dr',
    config: {
      level: 2,
      operator: '+',
      filter: false
    },
    description: null,
    whetherExpanded: true, // 加密货币
    popover: false
  },
  {
    fieldId: 'rollforward-100-1',
    name: 'Income received',
    type: 'ACCOUNT',
    balanceType: 'Dr',
    config: {
      level: 2,
      operator: '+',
      filter: false
    },
    description: null,
    whetherExpanded: true, // 加密货币
    popover: false
  },
  {
    fieldId: 'rollforward-100-2',
    name: 'Proceeds from issuance of debt',
    type: 'ACCOUNT',
    balanceType: 'Dr',
    config: {
      level: 2,
      operator: '+',
      filter: true
    },
    description: null,
    whetherExpanded: true, // 加密货币
    popover: false
  },
  {
    fieldId: 'rollforward-100-3',
    name: 'Issuance of common stock',
    type: 'ACCOUNT',
    balanceType: 'Dr',
    config: {
      level: 2,
      operator: '+',
      filter: true
    },
    description: null,
    whetherExpanded: true, // 加密货币
    popover: false
  },
  {
    fieldId: 'rollforward-100-4',
    name: 'Proceeds from settlement of investments',
    type: 'ACCOUNT',
    balanceType: 'Dr',
    config: {
      level: 2,
      operator: '+',
      filter: true
    },
    description: null,
    whetherExpanded: true, // 加密货币
    popover: false
  },
  {
    fieldId: 'rollforward-100-5',
    name: 'Customer deposit funds',
    type: 'ACCOUNT',
    balanceType: 'Dr',
    config: {
      level: 2,
      operator: '+',
      filter: true
    },
    description: null,
    whetherExpanded: true, // 加密货币
    popover: false
  },
  {
    fieldId: 'rollforward-100-agg',
    name: 'Total assets acquired',
    type: 'AGGREGATION',
    balanceType: 'Dr',
    config: {
      operator: '+',
      filter: false,
      formula: [
        'rollforward-100-0',
        'rollforward-100-1',
        'rollforward-100-2',
        'rollforward-100-3',
        'rollforward-100-4',
        'rollforward-100-5',
        'rollforward-100-agg'
      ]
    },
    description: null,
    whetherExpanded: false,
    popover: true // 计算公式弹窗
  },
  {
    fieldId: 'rollforward-200',
    name: 'Assets disposed',
    type: 'ACCOUNT',
    balanceType: 'Dr',
    config: {
      level: 1,
      filter: false
    },
    description: null,
    whetherExpanded: true, // 加密货币
    popover: false
  },
  {
    fieldId: 'rollforward-200-0',
    name: 'Sales of crypto assets',
    type: 'ACCOUNT',
    balanceType: 'Dr',
    config: {
      level: 2,
      operator: '-',
      filter: false
    },
    description: null,
    whetherExpanded: true, // 加密货币
    popover: false
  },
  {
    fieldId: 'rollforward-200-1',
    name: 'Payment usage',
    type: 'ACCOUNT',
    balanceType: 'Dr',
    config: {
      level: 2,
      operator: '-',
      filter: false
    },
    description: null,
    whetherExpanded: true, // 加密货币
    popover: false
  },
  {
    fieldId: 'rollforward-200-2',
    name: 'Repayments of debt',
    type: 'ACCOUNT',
    balanceType: 'Dr',
    config: {
      level: 2,
      operator: '-',
      filter: true
    },
    description: null,
    whetherExpanded: true, // 加密货币
    popover: false
  },
  {
    fieldId: 'rollforward-200-3',
    name: 'Payments of dividends',
    type: 'ACCOUNT',
    balanceType: 'Dr',
    config: {
      level: 2,
      operator: '-',
      filter: true
    },
    description: null,
    whetherExpanded: true, // 加密货币
    popover: false
  },
  {
    fieldId: 'rollforward-200-4',
    name: 'Purchases of investments',
    type: 'ACCOUNT',
    balanceType: 'Dr',
    config: {
      level: 2,
      operator: '-',
      filter: true
    },
    description: null,
    whetherExpanded: true, // 加密货币
    popover: false
  },
  {
    fieldId: 'rollforward-200-5',
    name: 'Customer withdraw funds',
    type: 'ACCOUNT',
    balanceType: 'Dr',
    config: {
      level: 2,
      operator: '-',
      filter: true
    },
    description: null,
    whetherExpanded: true, // 加密货币
    popover: false
  },
  {
    fieldId: 'rollforward-200-agg',
    name: 'Total assets disposed',
    type: 'AGGREGATION',
    balanceType: 'Dr',
    config: {
      operator: '-',
      filter: false,
      formula: [
        'rollforward-200-0',
        'rollforward-200-1',
        'rollforward-200-2',
        'rollforward-200-3',
        'rollforward-200-4',
        'rollforward-200-5',
        'rollforward-200-agg'
      ]
    },
    description: null,
    whetherExpanded: false,
    popover: true // 计算公式弹窗
  },
  {
    fieldId: 'rollforward-300',
    name: 'Gains(Realized and Unrealized)',
    type: 'AGGREGATION',
    balanceType: 'Dr',
    config: {
      operator: '+',
      filter: false
    },
    description: null,
    whetherExpanded: false,
    popover: false
  },
  {
    fieldId: 'rollforward-400',
    name: 'Losses(Realized and Unrealized)',
    type: 'AGGREGATION',
    balanceType: 'Dr',
    config: {
      operator: '-',
      filter: false
    },
    description: null,
    whetherExpanded: false,
    popover: false
  },
  {
    fieldId: 'rollforward-end',
    name: 'Total Fair Value at the end of period',
    type: 'AGGREGATION',
    balanceType: 'Dr',
    config: {
      lineBold: true,
      filter: false,
      formula: [
        'rollforward-begin',
        'rollforward-100-agg',
        'rollforward-200-agg',
        'rollforward-300',
        'rollforward-400',
        'rollforward-end'
      ]
    },
    description: null,
    whetherExpanded: false,
    popover: true // 计算公式弹窗
  },
  {
    fieldId: 'rollforward-end00000',
    name: 'Realized vs. unrealized gains and losses',
    type: 'ACCOUNT',
    balanceType: 'Dr',
    config: {
      level: 1,
      filter: false,
      lineBold: true
    },
    description: null,
    whetherExpanded: false,
    popover: false
  },
  {
    fieldId: 'rollforward-500',
    name: 'Gain (Loss) on crypto activities',
    type: 'ACCOUNT',
    balanceType: 'Dr',
    config: {
      level: 2,
      filter: false,
      operator: '+'
    },
    description: null,
    whetherExpanded: false,
    popover: false
  },
  {
    fieldId: 'rollforward-600',
    name: 'Unrealized gain (loss) - crypto fair value measurement',
    type: 'ACCOUNT',
    balanceType: 'Dr',
    config: {
      level: 2,
      filter: false,
      operator: '+'
    },
    description: null,
    whetherExpanded: false,
    popover: false
  },
  {
    fieldId: 'rollforward-500-600-agg',
    name: 'Net gain/loss from crypto activities',
    type: 'AGGREGATION',
    balanceType: 'Dr',
    config: {
      filter: false,
      formula: ['rollforward-500', 'rollforward-600', 'rollforward-500-600-agg']
    },
    description: null,
    whetherExpanded: false,
    popover: true // 计算公式弹窗
  }
]
