import { createRouter, createWebHistory } from 'vue-router'
import globalGuard from './globalGuard'

const router = createRouter({
  history: createWebHistory(),
  routes: [
    //   主体业务
    {
      name: 'defaultLayout',
      path: '/', // 这个并不是通配符，而是严格匹配
      component: () => import('@/layout/index.vue'),
      children: [
        {
          name: 'reports',
          path: 'reports',
          redirect: () => ({ name: 'reports-dashboard' }),
          meta: { requiresAuth: true },
          component: () => import('@/pages/Financials/DetailPage.vue'),
          children: [
            {
              name: 'reports-dashboard',
              path: 'dashboard',
              component: () => import('@/pages/Financials/Dashboard/IndexPage.vue')
            },
            {
              name: 'reports-balance-sheet',
              path: 'balance-sheet',
              component: () => import('@/pages/Financials/Reports/BalancePage.vue')
            },
            {
              name: 'reports-income-statement',
              path: 'income-statement',
              component: () => import('@/pages/Financials/Reports/IncomePage.vue')
            },
            {
              name: 'reports-cash-flow-statement',
              path: 'cash-flow-statement',
              component: () => import('@/pages/Financials/Reports/CashFlowPage.vue')
            },
            {
              name: 'reports-general-ledger',
              path: 'general-ledger',
              component: () => import('@/pages/Financials/Reports/GeneralLedgerPage.vue')
            },
            {
              name: 'reports-significant-holdings',
              path: 'significant-holdings',
              component: () => import('@/pages/Financials/Reports/HoldingsPage.vue')
            },
            {
              name: 'reports-restrictions',
              path: 'restrictions-crypto-assets',
              component: () => import('@/pages/Financials/Reports/RestrictionsPage.vue')
            },
            {
              name: 'reports-roll-forward',
              path: 'crypto-assets-roll-forward',
              component: () => import('@/pages/Financials/Reports/RollForwardPage.vue')
            }
          ]
        }
      ]
    },
    // 404 页面
    {
      name: 'notFound',
      path: '/404',
      component: () => import('@/pages/NotFoundPage.vue'),
      meta: { requiresAuth: false }
    },

    // 所有未被匹配的路由都漏到这里，然后重定向到home
    {
      path: '/:pathMatch(.*)',
      name: 'not-found',
      redirect: () => ({ name: 'notFound' })
    }
  ]
})

router.beforeEach(globalGuard.init)
router.beforeEach(globalGuard.authVerify)
// router.beforeEach(globalGuard.versionCheck)
// router.beforeEach(globalGuard.mixpanelStatistics)

export default router
