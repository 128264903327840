/* eslint-disable consistent-return */
import { isEmpty } from 'lodash-es'
import { useGlobalStore } from '@/stores/modules/global'
import { RouteLocationNormalized } from 'vue-router'

export default {
  init: async () => {
    const globalStore = useGlobalStore()
    if (isEmpty(globalStore.languages)) {
      await globalStore.globalConfigInit()
    }
  },
  versionCheck: async () => {
    if (process.env.VITE__APP_COMMAND__ === 'serve') return
    // 判断是否与生成的版本信息一致
    const currentVersion = localStorage.getItem('UPDATE_VERSION')
    if (currentVersion && String(process.env.VITE__APP_VERSION__) !== currentVersion) {
      window.location.reload()
      localStorage.setItem('UPDATE_VERSION', String(process.env.VITE__APP_VERSION__))
    }
  },
  authVerify: async (to: RouteLocationNormalized) => {
    const globalStore = useGlobalStore()
    const token = window.sessionStorage.getItem('elv-token')
    if (token !== null && globalStore.projectList.length === 0) {
      await globalStore.fetchProjectList()
    }
    if (to.name === 'notFound') return

    // 这个路由不需要验证
    if (!to.matched.some((record) => record.meta.requiresAuth)) return

    if (!token) {
      let timer: any = null
      timer = setInterval(async () => {
        if (window.sessionStorage.getItem('elv-token')) {
          clearInterval(timer)
          await globalStore.fetchProjectList()
          return to
        }
      }, 30)
    }
  }
}
