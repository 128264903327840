import '@/assets/fonts/font.css'
import { createApp } from 'vue'
import { store } from '@/stores/index'
import App from './App.vue'
import router from './router'
import './assets/js/baidu'
import './assets/js/google'
import { useI18n } from '@/i18n/index'
// eslint-disable-next-line import/no-unresolved
import 'virtual:svg-icons-register'
// 按需要引入 element-plus
import { globalRegister } from './gloable'
/// #if COMMAND === 'serve'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
/// #else
import 'element-plus/theme-chalk/el-loading.css'
import 'element-plus/theme-chalk/el-message.css'
/// #endif

// 自定义指令
import directives from './directive'
// 引入需要注册的全部组件
import globalComponent from '@/components/index'
import updateVersion from '@/lib/updateVersion'

const app = createApp(App)

app.use(globalRegister).use(useI18n).use(store).use(router).use(directives)
/// #if COMMAND === 'serve'
app.use(ElementPlus)
/// #endif

// 注册全局的组件 （对所有需要注册的组件进行遍历并注册）
// eslint-disable-next-line guard-for-in
for (const componentItem in globalComponent) {
  app.component(componentItem, globalComponent[componentItem])
}

app.mount('#app')

updateVersion()
