<template>
  <el-button class="elv-button" v-bind="$attrs">
    <template v-for="(_, name) in $slots" #[name]="scope">
      <slot vlese :name="name" v-bind="scope || {}" />
    </template>
  </el-button>
</template>
<script setup lang="ts">
defineOptions({
  inheritAttrs: false
})
const props = defineProps({
  width: {
    type: [String, Number],
    default: ''
  },
  height: {
    type: [String, Number],
    default: '36'
  }
})

const buttonWidth = computed(() => {
  if (!props.width) return 'auto'
  if (!Number(props.width)) return props.width
  return `${props.width}px`
})

const buttonHeight = computed(() => {
  if (!Number(props.height)) return props.height
  return `${props.height}px`
})
</script>

<style lang="scss" scoped>
.elv-button {
  width: v-bind('buttonWidth');
  height: v-bind('buttonHeight');
  border-radius: 4px;
  font-family: 'Plus Jakarta Sans';
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #0e0f11;

  &.el-button--primary {
    border: 0px;
    color: #ffffff;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    background: #1753eb;
    box-shadow: 0px 2px 3px rgba(44, 49, 64, 0.1), 0px 0px 1px #0048ff;

    &:not(.is-disabled):not(.is-plain):focus,
    &:not(.is-disabled):not(.is-plain):hover {
      background: #2f63eb;
      color: #ffffff;
      box-shadow: 0px 2px 3px rgba(44, 49, 64, 0.1), 0px 0px 1px #0048ff;
    }

    &:not(.is-disabled):not(.is-plain):active {
      background: #1343bf;
      color: #ffffff;
      box-shadow: 0px 2px 3px rgba(44, 49, 64, 0.1), 0px 0px 1px #0048ff;
    }
  }

  :deep(:not(.el-icon) svg) {
    margin-right: 7px;
    transition: all 0.1s;
  }

  &.is-round {
    border-radius: 30px;
  }

  &:not(.is-disabled):not(.is-plain):focus,
  &:not(.is-disabled):not(.is-plain):hover {
    border: 1px solid #5e85eb;
    background: #fff;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.08);
    color: #0e0f11;
  }

  &:not(.is-disabled):not(.is-plain):active {
    border: 1px solid #1753eb;
    background: #fff;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.08);
    color: #0e0f11;
  }

  &.is-plain {
    border: 1px solid #c8d6fa;
    filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.04));
    // border-radius: 24px;
    box-shadow: none;
    background-color: #fff;
    color: #1753eb;
    font-weight: 500;

    &:not(.is-disabled):deep(svg) {
      fill: #1753eb;
    }

    &:not(.is-disabled):focus,
    &:not(.is-disabled):hover {
      border: 1px solid #5e85eb;
      color: #1343bf;

      :deep(svg) {
        fill: #1343bf;
      }
    }
  }

  &.is-disabled {
    color: #838d95 !important;
    background: #edf0f3 !important;
    box-shadow: 0px 2px 3px rgba(44, 49, 64, 0.1), 0px 0px 1px #a3a3a3 !important;
  }

  .elv-button__loading {
    display: block;
    width: 24px;
    height: 24px;
    // margin-right: 8px;
    animation: elv-loading-rotate 2s linear infinite;
  }
}
@keyframes elv-loading-rotate {
  to {
    transform: rotate(360deg);
  }
}
</style>
Ï
