// 多组件库的国际化和本地项目国际化兼容
import { App, WritableComputedRef } from 'vue'
import { type I18n, createI18n } from 'vue-i18n'

// element-plus国际化
import elEnLocale from 'element-plus/lib/locale/lang/en'
import elZhLocale from 'element-plus/lib/locale/lang/zh-cn'

function siphonI18n(prefix = 'en') {
  return Object.fromEntries(
    Object.entries(import.meta.glob('./locales/*.ts', { eager: true })).map(([key, value]: any) => {
      const matched = key.match(/([A-Za-z0-9-_]+)\./i)[1]
      return [matched, value.default]
    })
  )[prefix]
}

export const localesConfigs = {
  zh: {
    ...siphonI18n('zh'),
    ...elZhLocale
  },
  en: {
    ...siphonI18n('en'),
    ...elEnLocale
  }
}

/** 此函数只是配合i18n Ally插件来进行国际化智能提示，并无实际意义（只对提示起作用） */
export const $t = (key: string) => key

export const i18n: I18n = createI18n({
  legacy: false,
  locale: window.localStorage.getItem('language') ?? 'en',
  fallbackLocale: 'en',
  globalInjection: true,
  silentTranslationWarn: true,
  missingWarn: false,
  messages: localesConfigs
})

export function useI18n(app: App) {
  app.use(i18n)
}

/**
 * 国际化转换工具函数（自动读取根目录locales文件夹下文件进行国际化匹配）
 * @param message message
 * @returns 转化后的message
 */
export function transformI18n(message: any = '') {
  if (!message) return ''

  // 处理存储动态路由的title,格式 {zh:"",en:""}
  if (typeof message === 'object') {
    // eslint-disable-next-line prefer-destructuring
    const locale: string | WritableComputedRef<string> | any = i18n.global.locale
    return message[locale?.value]
  }

  const key = message.match(/^\w+/)?.[0]
  if (key && Object.keys(siphonI18n('en')).includes(key)) {
    return i18n.global.t.call(i18n.global.locale, message)
  }
  return message
}
