export default {
  common: {
    elven: 'elven',
    team: 'team',
    email: 'Email',
    emailAddress: 'Email Address',
    password: 'Password',
    captcha: 'Verification Code',
    sendCaptchaCountdown: 's',
    agreementText: 'I have read & agree to the ', // 同意协议文案
    userService: 'Terms of Service ', // 用户协议
    serviceAgreement: 'Privacy Policy', // 隐私政策
    noData: 'No Data',
    address: 'Address',
    addresses: 'addresses',
    addressName: 'Address Name',
    dateTime: 'Datetime',
    currency: 'Currency',
    amountIn: 'Amount(in)',
    amountOut: 'Amount(Out)',
    FromTo: 'From/To',
    LineChart: 'Line chart',
    barChart: 'bar chart',
    numberChart: 'number chart',
    equal: '=',
    greaterThan: '>',
    lessThan: '<',
    range: 'range',
    start: 'from',
    end: 'to',
    to: 'to',
    allRightsReserved: 'All rights reserved.',
    updatedOn: 'Updated on ',
    dataSources: 'Data source',
    share: 'Share',
    financials: 'Financials',
    business: 'Business',
    valuation: 'Valuation',
    project: 'Project',
    projects: 'Projects',
    totalProject: 'Total Project',
    list: 'List',
    results: 'results',
    result: 'result',
    group: 'Group',
    groupView: 'Group View',
    secondaryGroup: 'Secondary Group',
    tertiaryGroup: 'Tertiary Group',
    color: 'COLOR',
    block: 'BLOCK',
    size: 'SIZE',
    axise: 'Axise',
    rulesForSubtotal: 'Rules for Subtotal',
    keepGroup: 'Keep the group view',
    choose: 'Choose',
    stick: 'Stick',
    unstick: 'Unstick',
    hideColumn: 'Hide Column',
    sortAscending: 'Sort ascending',
    sortDescending: 'Sort descending',
    monitor: 'Monitor',
    if: 'if',
    then: 'Then',
    frequency: 'Frequency',
    name: 'Name',
    condition: 'Condition',
    soon: 'SOON',
    total: 'Total',
    marketTotal: 'Total',
    addFilter: 'Add Filter',
    pleaseChoose: 'Please Choose',
    searchMetrics: 'Search Metrics',
    createdOn: 'Created On',
    lastExcution: 'Last excution',
    everyDay: 'Every Day',
    runsEveryDay: 'Runs Every Day',
    keepAlerts: 'Keep the Alert',
    alertTime: 'Sent At UTC 00 ：30 Every Day',
    account: 'Account',
    contact: 'Contact',
    openingBalance: 'Opening Balance',
    closingBalance: 'Closing Balance',
    journalEntries: 'Journal Entries',
    debit: 'Debit',
    credit: 'Credit',
    event: 'Event',
    txCount: 'Tx Count',
    amount: 'Amount',
    millions: 'Millions',
    billions: 'Billions',
    thousands: 'Thousands',
    individuals: 'Individual units',
    reportUnit: 'In {unit} of USD',
    amountsUnit: 'Amounts in { unit }, USD',
    willBeComingSoon: 'Will be coming soon',
    comingSoon: 'Coming soon',
    description: 'Description',
    chain: 'Chain',
    chains: 'chains',
    basedAddressOnChain: 'Based on {addressCount} {address} on {chainCount} {chain}',
    allMetrics: 'All Metrics',
    addMetric: 'Add metric',
    basicInfo: 'BASIC INFO',
    followTableSorting: 'Follow the table sorting',
    numberOfGroups: 'Will Be Divided Into {count} Groups',
    fieldTotalRule: '{field} Total Rule: ',
    fieldSubtotalRule: '{field} Subtotal Rule: ',
    Is: 'Is',
    'Is not': 'Is not',
    'Equal to': 'Equal to',
    'More than': 'More than',
    'Less than': 'Less than',
    'Within range': 'Within range',
    'Outside range': 'Outside range',
    'Is empty': 'Is empty',
    'Is not empty': 'Is not empty',
    shareTo: 'Share to',
    selectAccountingPeriod: 'Select the accounting period: ',
    createAccount: 'Create an account',
    verifyEmail: 'Verify your email',
    expandControlPanel: 'Expand Control Panel',
    copied: 'copied',
    moreInformation: 'For more information, please ',
    selected: 'Selected',
    select: 'Select',
    syncFailed: 'Sync failed',
    syncing: 'Syncing',
    sum: 'SUM',
    type: 'Type',
    status: 'Status',
    priority: 'Priority',
    conditions: 'Conditions',
    actions: 'Actions',
    date: 'Date',
    is: 'is',
    accountName: 'Account name',
    accountType: 'Account type',
    contactType: 'Contact type',
    doesNotEqual: 'does not equal',
    isGreaterThanOrEqualTo: 'is greater than or equal to',
    isGreaterThan: 'is greater than',
    isLessThanOrEqualTo: 'is less than or equal to',
    isLessThan: 'is less than',
    isBetween: 'is between',
    on: 'on',
    before: 'before',
    after: 'after',
    between: 'between',
    api: 'API',
    wallet: 'Wallet',
    wallets: 'Wallets',
    exchange: 'Exchange',
    exchanges: 'Exchanges',
    fileName: 'Filename',
    csvFiles: 'CSV Files',
    customizedAPI: 'Customized API',
    entity: 'Entity',
    entities: 'Entities',
    users: 'Users',
    month: 'month',
    annually: 'Annually',
    free: 'Free',
    basic: 'Basic',
    pro: 'Pro',
    enterprise: 'Enterprise',
    unlimited: 'Unlimited',
    CEO: 'Chief Executive Officer (CEO)',
    CFO: 'Chief Financial Officer (CFO)',
    CAO: 'Chief Accounting Officer (CAO)',
    financeManager: 'Finance Manager',
    accountingManager: 'Accounting Manager',
    financialAnalyst: 'Financial Analyst',
    financialAdvisor: 'Financial Advisor',
    taxSpecialist: 'Tax Specialist',
    others: 'Others',
    cryptoNativeFirms: 'Crypto Native Firms',
    assetManagementFirms: 'Asset Management Firms',
    onChainProjects: 'On-Chain Projects',
    MiningStakingNodeOperators: 'Mining, Staking and Node operators',
    ventureCapitals: 'Venture Capitals',
    done: 'Done',
    transactionsEntitiesUsers: 'Transactions, Entities, Users',
    enterprisePlanInfo: 'For large organization with more transaction, support and performance needs.',
    projectSettings: 'Project Settings',
    entitySettings: 'Entity Settings',
    general: 'General',
    integrations: 'Integrations',
    members: 'Members',
    member: 'Member',
    PlanBilling: 'Plan & Billing',
    security: 'Security',
    customPricing: 'Custom Pricing',
    subscription: 'Subscription',
    currentPlan: 'Current Plan',
    startDate: 'Start date',
    expirationDate: 'Expiration date',
    subscriptionStatus: 'Subscription status',
    deleteMember: 'Delete member',
    deleteEntity: 'Delete entity',
    administrator: 'Administrator',
    report: 'Report',
    reports: 'Reports',
    settings: 'Settings',
    automation: 'Automation',
    view: 'View',
    ledger: 'Ledger',
    viewListPermission: 'View {operating} list',
    viewDetailPermission: 'View {operating} detail',
    addNewPermission: 'Add new {operating}',
    editPermission: 'Edit {operating}',
    deletePermission: 'Delete {operating}',
    exportPermission: 'Export {operating}',
    generatePermission: 'Generate {operating}',
    reviewPermission: 'Review {operating}',
    syncToErp: 'Sync to ERP',
    source: 'source',
    add: 'add',
    edit: 'edit',
    delete: 'delete',
    export: 'export',
    review: 'review',
    rule: 'Rule',
    active: 'Active',
    expired: 'Expired',
    next: 'Next',
    prev: 'Prev',
    skip: 'Skip',
    continue: 'Continue',
    continueWithEmail: 'Continue with Email',
    signInWithVerificationCode: 'Sign in with verification code',
    signInWithPassword: 'Sign in with password',
    or: 'OR',
    sameInAllEntities: 'Same in all entities',
    alreadyUser: 'Already a user?',
    newToElven: 'New to Elven?',
    file: 'File',
    uploadFile: 'Upload file',
    bank: 'Bank',
    client: 'Client',
    activity: 'Activity',
    uploadAttachments: 'Upload attachments',
    balance: 'Balance',
    '90D Revenue': '90D Revenue',
    '90D Expense': '90D Expense',
    '90D Profit': '90D Profit',
    revenue: 'Revenue',
    expense: 'Expense',
    profit: 'Profit',
    ruleName: 'Rule name',
    selectConditionsInfo: 'If all of the following conditions are met:',
    selectCondition: 'Select a condition',
    performActions: 'Perform the following actions:',
    categorizeTransaction: 'Categorize transaction',
    otherBank: 'Other Bank',
    period: 'Period',
    interval: 'Interval',
    quarter: 'Quarter',
    year: 'Year',
    day: 'Day',
    week: 'Week',
    Month: 'Month',
    regenerating: 'Report is being generated',
    tradingVolume: 'Trading volume'
  },
  report: {
    Address: 'Address',
    'Address Name': 'Address Name',
    Datetime: 'Datetime',
    Currency: 'Currency',
    'Amount (In)': 'Amount (In)',
    'Amount (Out)': 'Amount (Out)',
    'From/To': 'From/To',
    Description: 'Description',
    Debit: 'Debit',
    Credit: 'Credit',
    Event: 'Event',
    'Opening Balance': 'Opening Balance',
    'Closing Balance': 'Closing Balance',
    totalInflow: 'Total Inflow',
    totalOutflow: 'Total Outflow',
    totalDebit: 'Total Debit',
    totalCredit: 'Total Credit',
    'Type of Assets': 'Type of Assets',
    'Nominal Amount': 'Nominal Amount',
    'Market Value': 'Market Value',
    '% of total net assets': '% of total net assets',
    Multiples: 'Multiples',
    IRR: 'IRR',
    'Cost basis': 'Cost basis',
    'Realized Proceeds': 'Realized Proceeds',
    'Cost basis of Realized Proceeds': 'Cost basis of Realized Proceeds',
    ON_CHAIN: 'On-Chain',
    OFF_CHAIN: 'Off-Chain',
    GLOBAL: 'Consolidated',
    syncingWalletsOrExchanges: 'Syncing Wallets / Exchanges',
    synchronizingTotal: '{total} source is synchronizing',
    synchronizingTotals: '{progress}/{total} sources are synchronizing',
    syncingSources: 'Syncing sources ({progress}/{total})',
    syncTransactionsTotal: ' Transactions have been imported, ',
    syncTransactionsComplete: 'Sync complete, ',
    syncComplete: "Sync complete, please click the 'regenerate reports' button to update the reports.",
    deletedSourceTitle: 'Delete source “{name}”',
    deletedSourceInfo: 'Will delete {total} transactions form this source, this operation cannot be recovered.',
    batchEdit: 'Batch Edit',
    totalContacts: '{total} Contacts',
    contactType: 'Contact Type',
    employee: 'Employee',
    vendor: 'Vendor',
    customer: 'Customer',
    investor: 'Investor',
    community: 'Community',
    debtor: 'Debtor',
    sponsor: 'Sponsor',
    memo: 'Memo',
    transaction: 'Transaction',
    sent: 'SENT',
    received: 'RECEIVED',
    internal: 'INTERNAL',
    source: 'Source',
    Source: 'Source',
    Name: 'Name',
    Memo: 'Memo',
    Assets: 'Assets',
    assets: 'assets',
    assetsPortfolio: 'Assets Portfolio',
    Quantity: 'Quantity',
    'Cost Basis': 'Cost Basis',
    'Fair Value': 'Fair Value',
    'Address/Bank Account': 'Address/Bank Account',
    'Significant holdings report': 'Significant holdings report',
    allSources: 'All Sources',
    Transactions: 'Transactions',
    transactionDetail: 'Transaction Detail',
    datetime: 'Datetime',
    amount: 'Amount',
    from: 'From',
    to: 'To',
    accounting: 'Accounting',
    transactionsTotal: '{total} Transactions',
    regenerateReports: 'Regenerate reports',
    regenerating: 'Regenerating',
    capitalGainORLoss: 'Capital Gain/Loss',
    marketValue: 'Market Value',
    sourceName: 'Source name',
    key: 'Key',
    secret: 'Secret',
    passphrase: 'Passphrase',
    accountingPreview: 'Accounting entries preview:',
    accountingPreviewInfo: 'The accounting entries will be effective when the statements are updated',
    regenerateTheReports: 'Transaction data have changed, Click to regenerate the reports',
    regenerated: 'Regenerated',
    generating: 'Generating',
    'Rule Engine Configure Accounting Entries and Cost Basis':
      'Rule Engine Configure Accounting Entries and Cost Basis',
    'Generate Income Statement report': 'Generate Income Statement report',
    'Generate Balance Sheet report': 'Generate Balance Sheet report',
    'Generate Restrictions of Crypto Assets report': 'Generate Restrictions of Crypto Assets report',
    'Generate Cash Flow Statement report': 'Generate Cash Flow Statement report',
    'Generate Crypto Assets Roll Forward report': 'Generate Crypto Assets Roll Forward report',
    'Generate Significant Holdings report': 'Generate Significant Holdings report',
    'Generate note L1': 'Generate note L1',
    'Generate note L2': 'Generate note L2',
    'Reports have been regenerated successfully': 'Reports have been regenerated successfully',
    Tabulating: 'Tabulating',
    'Regenerate Failed, please check your transaction data': 'Regenerate Failed, please check your transaction data',
    deleting: 'Deleting',
    reportRegeneratedSuccess: 'Report has been regenerated',
    noSet: 'No set',
    transactionsRule: '{total} transactions match the rule:',
    similarTransactions: '{total} similar transactions:',
    setEvent: 'Set Event',
    resolutionCenter: 'Resolution Center',
    resolve: 'Resolve',
    wellDone: 'Well Done',
    transactionsCategorized: 'All transactions are categorized',
    duplicateTransactions: 'No suspected duplicate transactions',
    keepAll: 'Keep All',
    keepAllConfirm: 'These transactions look like duplicate data, are you sure you want to keep them anyway?',
    possibleDuplicates: 'Possible Duplicates',
    possibleDuplicatesInfo:
      '{total} groups of transactions are similar to each other, is it possible that they are the same?',
    possibleDuplicateInfo:
      '{total} group of transactions are similar to each other, is it possible that they are the same?',
    unknownEvent: 'Unknown Event',
    unknownEventsInfo: '{total} transactions can not identify business activities.',
    unknownEventInfo: '{total} transaction can not identify business activities.',
    disposalExceedsBalance: 'Disposal exceeds balance - {currency}',
    disposalExceedsBalanceInfo:
      'In certain transactions, {currency} disposal exceeds balance. Please verify sources or category accuracy.',
    errorReport: '{sheet} calculation error',
    errorReportInfo: 'There are some numerical errors in the report',
    discard: 'Discard',
    batchConfirm: 'Batch Confirm',
    batchSettingEvents: 'Batch setting events',
    categorizeTransactionAs: 'Categorize transaction as',
    transactionType: 'Transaction Type',
    inflow: 'Inflow',
    outflow: 'Outflow',
    manuallyExecuteRules: 'Manually execute all rules',
    rulesAreExecuting: 'Rules are executing',
    operating: 'Operating',
    financing: 'Financing',
    investing: 'Investing',
    equals: 'equals',
    automaticallyReviewed: 'Automatically mark as reviewed',
    deleteRule: 'Delete rule',
    executeRule: 'Execute rule',
    unnamed: 'Unnamed',
    unidentified: 'Unidentified',
    named: 'Named',
    addYourWallet: 'Add your first wallet',
    addWalletInfo: 'Add an wallet to import on-chain transaction data',
    addYourExchange: 'Add your first exchange',
    addExchangeInfo: 'Get trading data from an exchange use API or Auth',
    uploadCSVFile: 'Upload a CSV file',
    uploadCSVFileInfo: 'Import offline trading data by uploading a CSV file',
    welcomeUser: 'Welcome, {userName}',
    startCreateProject: 'Start creating your first project now!',
    choosePlan: 'Choose a subscription plan',
    applyForTrial: 'Apply for Trial',
    applyForTrialInfo:
      'Thanks for your interest, please fill out the form below and we will contact you as soon as possible',
    applyWaiting: 'You are already on the waiting list, please wait patiently for our reply',
    yourOrganization: 'Your organization',
    yourPosition: 'Your position',
    industry: 'Industry',
    numberOfHistoricalTransactions: 'Number of historical transactions',
    otherCryptoFinancialProductsUsed: 'Other crypto financial products used',
    howCanElvenHelpYou: 'How can Elven help you?',
    projectName: 'Project name',
    entityName: 'Entity name',
    projectLogo: 'Project logo',
    entityLogo: 'Entity logo',
    countryRegion: 'Country/Region',
    dataStartingTime: 'Data starting Time',
    costBasisMethodology: 'Cost basis methodology',
    defaultCurrency: 'Default currency',
    preferredCurrencyUnit: 'Preferred currency Unit',
    projectHasBeenCreated: 'Your project has been created!',
    getStartedOnFewEasySteps: 'Get started on a few easy steps:',
    cancelRequestInfo:
      'You have applied for a trial and it is currently pending approval. Do you want to cancel it now?',
    needHelp: 'Need help?',
    bookDemoTitle: 'Book a Demo',
    bookDemoInfo: 'You can schedule a remote video demo where our team of experts will guide you through using Elven.',
    ReadDocsTitle: 'Read this Docs',
    ReadDocsInfo:
      'Following our comprehensive help documentation, which will guide you through each step of using Elven.',
    ruleCount: '{total} rules',
    startingFirstProject: 'Starting from creating your first project.',
    category: 'Category',
    fileTemplate: 'File template',
    totalLines: 'Total lines',
    unrecognizableLines: 'Unrecognizable lines',
    recognizedLines: 'Recognized lines',
    onlyGenericFormatCSV: 'Only generic format CSV is supported, ',
    onlyAcceptCSV: 'Only accept CSV files that adhere to the Elven standard format, ',
    clickDownloadTemplate: 'click to download the template file',
    parsingFile: 'Parsing file',
    parsingFailed: 'Parsing failed',
    unableRecognizeYourFile: 'Unable to recognize your file',
    dragUploadOrBrowse: 'Drag and drop to upload, or browse files.',
    uploadCSVMaxSize: 'Supported file types: csv, max size: 100m',
    selectAccountInThisFile: 'Select account in This File',
    added: 'Added',
    dataCutoffTime: 'Data cutoff time',
    editWallet: 'Edit Wallet',
    editExchange: 'Edit Exchange',
    editCSV: 'Edit CSV',
    generalTransactions: 'General Transactions',
    bankTransactions: 'Bank Transactions',
    exchangeTrade: 'Exchange (Trade)',
    exchangeDeposit: 'Exchange (Deposit)',
    exchangeWithdraw: 'Exchange (Withdraw)',
    completeHistory: 'Complete history',
    dataBefore: 'Data before',
    dataAfter: 'Data after',
    dataBetween: 'Data between',
    transactionDataOption: 'Transaction data option',
    addSources: 'Add sources',
    addSourcesInfo: 'Add on-chain wallet or exchange for your project, or upload CSV files.',
    reviewTransactions: 'Review transactions',
    reviewTransactionsInfo: 'Review transactions and match each one with the correct business activity tag.',
    generateReports: 'Generate reports',
    generateReportsInfo: 'Generate financial statements and an crypto asset disclosure with just one click!',
    addMemo: 'Add memo',
    groupsDuplicatesTotal: '{total} groups of transactions may be duplicates:',
    revenueComposition: 'Revenue composition',
    userAssets: 'User Assets',
    companyAssets: 'Company Assets',
    assetsdistribution: 'Assets distribution',
    holdings: 'Holdings',
    netIncome: 'Net income',
    expenses: 'Expenses',
    revenues: 'Revenues',
    totalAssets: 'Total assets',
    totalLiabilities: 'Total liabilities',
    ownerEquity: "Owner's equity",
    customerAssets: 'Customer assets',
    unrestrictedCryptoAssets: 'Unrestricted crypto assets',
    restrictedCryptoAssets: 'Restricted crypto assets',
    totalCryptoAssets: 'Total crypto assets',
    'Total Fair Value at the end of period': 'Total Fair Value at the end of period',
    totalAssetsAcquired: 'Total assets acquired',
    totalAssetsDisposed: 'Total assets disposed'
  },
  title: {
    loginPageTitle: 'Powerful editing capabilities make the data display clearer.',
    loginPageTitle1: 'Create my own screener！',
    loginToExploreFurther: 'Login to explore further',
    setPasswordsTitle: 'You must set a password, later can password login.',
    exceedsExportLimit: 'Exceeds export limit',
    connectIntegrationInfo:
      "Link Elven to your {platform} account to automatically sync Elven's journal entry data to your {platform}.",
    AccountInPlatform: 'Account in {platform}',
    disconnectWithPlatform: 'Disconnect with {platform}',
    deleteAttachment: 'Delete attachment'
  },
  button: {
    delete: 'Delete',
    save: 'Save',
    saveAs: 'Save as',
    saveFailed: 'Save failed',
    discard: 'DisCard',
    contactUs: 'Contact Us',
    filter: 'Filter',
    editTable: 'Edit Table',
    groupBy: 'Group By',
    addGroup: 'Add Group',
    subGroup: 'Sub Group',
    export: 'Export',
    saveChanges: 'Save Changes',
    cancel: 'Cancel',
    addCondition: 'Add condition',
    ok: 'OK',
    yes: 'Yes',
    no: 'No',
    sendCaptchaText: 'send', // 发送之前验证码按钮文案
    sentCaptchaButtonText: 'Resend', // 点击发送之后 验证码按钮文案
    login: 'Login', // 登录按钮
    loginButtonTextInDetail: 'Log in', // 详情页登录按钮
    logOut: 'Log out', // 退出登录
    myAccount: 'My Account', // 我的账号
    bindEmail: 'Email', // 绑定邮箱
    saveAlert: 'Save Alert',
    newAlert: 'New Alert',
    editAlert: 'Edit Alert',
    clickView: 'click to view',
    connect: 'Connect',
    upload: 'Upload',
    type: 'Type',
    sources: 'Sources',
    events: 'Events',
    categories: 'Categories',
    currencies: 'Currencies',
    contacts: 'Contacts',
    dateTime: 'Datetime',
    allTime: 'All Time',
    reset: 'Reset',
    deleteContact: 'Delete Contact',
    deleteContacts: 'Delete Contacts',
    deleteAccount: 'Delete Account',
    deleteAccounts: 'Delete Accounts',
    edit: 'Edit',
    confirm: 'Confirm',
    backList: 'Back to list',
    addRule: 'Add Rule',
    editRule: 'Edit Rule',
    addWallet: 'Add Wallet',
    addExchange: 'Add Exchange',
    upLoadCSV: 'Upload CSV',
    learnMore: 'Learn more',
    create: 'Create',
    applyNow: 'Apply now',
    talkToSales: 'Talk to sales',
    cancelRequest: 'Cancel Request',
    submit: 'Submit',
    createProject: 'Create Project',
    start: 'Start',
    dismiss: 'Dismiss',
    confirmCancel: 'Confirm Cancel',
    addEntity: 'Add Entity',
    editEntity: 'Edit Entity',
    addMember: 'Add Member',
    editMember: 'Edit Member',
    add: 'Add',
    changeEmail: 'Change Email',
    otherSign: 'Other ways to sign in',
    signIn: 'Sign in',
    signUp: 'Sign up',
    getStarted: 'Get started',
    upgrade: 'Upgrade',
    markAllAsReviewed: 'Mark all as reviewed',
    markSelectedAsReviewed: 'Mark selected as reviewed',
    iKnow: 'I know',
    helpDocuments: 'Help & Documents',
    syncIntegration: 'Sync to {platform}',
    disconnect: 'Disconnect',
    accountMappings: 'Account Mappings',
    finish: 'Finish',
    resendInvitation: 'Resend invitation',
    goReview: 'Go to review transactions',
    reuploadFile: 'Re-upload file',
    uploadAnotherFile: 'Upload another file',
    uploadToExistingAccount: 'Upload to existing account',
    createNewAccount: 'Create new account',
    loadMore: 'Load more'
  },
  message: {
    sendEmail: 'Send notices to Email',
    enterAlertName: 'Enter your alert name',
    confirmEmailLogin: 'Please confirm your email to Log in.',
    loginTitle: 'Welcome to ', // 登录弹框title
    loginEmailPlaceholder: 'Please input the email address correctly', // 登录弹框邮箱提示 和 校验提醒
    loginEmailError: 'Please enter the correct email address.', // 校验提醒
    captchaPlaceholder: 'Enter verification code', // 登录弹框验证码提示和校验提醒
    captchaWarning: 'Please enter verification code.', // 登录弹框验证码提示和校验提醒
    captchaError: 'The verification code you entered is incorrect, please try again',
    emailError: 'Email or password is wrong, please try again',
    pleaseInputEmail: 'Please input email',
    pleaseInputName: 'Please enter your name',
    passwordError: 'Password least 8 characters long.Do use a mix of uppercase, lowercase, numbers, and symbols.',
    pleaseInputPassword: 'Please input password',
    PleaseInputCode: 'Please input code',
    saveSuccess: 'Success',
    copyFailed: 'Copy failed',
    copySuccessfully: 'Copy successfully',
    signInSuccess: 'Sign in success',
    signUpSuccess: 'Sign up success',
    onlyScreeners: 'You can only create 10 screeners!',
    saveChangeGroup: 'You have changed the group view, save this change?',
    pleaseEnterFilter: 'Please enter a value for filtering.',
    pleaseSelectFilter: 'Please select a metric for filtering.',
    rangeRequirements: "Range doesn't meet the requirements.",
    alertEmpty: 'Alert history is empty.',
    createdAlert: 'You haven’t created any alert.',
    areYouSure: 'Are you sure?',
    saveChangeAlert: 'You have changed the Alert conditions, save this change?',
    reportOnlySee100: 'Only the latest 100 items are displayed, if you need to see all the data, please',
    reportOnlySee20: 'Only the latest 20 items in each account are displayed, if you need to see all the data, please',
    alertUpdatedSuccuss: 'Alert updated succussfully!',
    alertCreatedSuccuss: 'Alert created succussfully!',
    fillAllBlanks: 'Please fill in all the blanks!',
    renameFailed: 'Rename Failed',
    pleaseEnterSourceName: 'Please enter the name of this source',
    pleaseEnterAccountName: 'Please enter the account name',
    enterSourceName: 'The entered Source Name is inconsistent',
    noPermission: 'You currently do not have this permission.',
    pleaseEnterContactName: 'Please enter the contact name.',
    pleaseSelectContactType: 'Please select a contact type, currently unset.',
    exportLimitMessage: 'The export limit is 1000 items per export, please use the filter to censor and export',
    editSuccess: 'Edit Success',
    deleteSuccess: 'Delete Success',
    regenerateFailed: 'Regenerate Failed, please check your transaction data',
    reportError: 'Report has been regenerated, but there are some errors, please check your data',
    transactionCategorized: 'Transaction categorized',
    transactionIgnored: 'Transaction ignored',
    transactionKeepAll: 'Transaction keep all',
    ruleEnabled: 'Rule is enabled',
    ruleDisabled: 'Rule is disabled',
    priorityChanged: 'Priority changed',
    ruleSavedSuccessfully: 'Rule saved successfully',
    pleaseWaitRules: 'Please wait for the end of the rules execution',
    executeRuleMessage: 'Do you want to execute all rules manually for all transaction data?',
    deleteRuleMessage: 'Delete rule “{name}” ?',
    addSixConditions: 'Add up to six conditions',
    deleteMemberMessage: 'Delete member “{email}” ?',
    deleteEntityMessage: 'Delete entity “{name}” ?',
    cancelSuccess: 'Cancel Success',
    submitSuccess: 'Submit Success',
    uploadSuccess: 'Upload Success',
    uploadError: 'Upload Failed, please try again',
    createProjectSuccess: 'Create Project Success',
    addEntitySuccess: 'Add Entity Success',
    agreedTermsOfService:
      'The first login will automatically create an account and means you have read and agreed to the ',
    LoginAgreedTermsOfService: 'Your login and registration action means you have read and agreed to the ',
    pleaseEnterVerificationCode: 'Please enter the verification code that has been sent to <span>{email}</span>',
    resendAfter: 'Resend after {tmp} s',
    loginInfoTitle: 'Your On-chain Accounting Partner',
    loginInfoDesc: 'One-stop solution for your crypto accounting and reporting',
    upgradePro: 'Upgrade to Pro',
    needMoreEntities: 'Need more entities? ',
    needMoreMembers: 'Need more members? ',
    reviewSuccess: 'Review Success',
    someTransactionsReviewedInfo: 'Some transactions can not be reviewed',
    maximumTransactionsReviewedInfo: 'The maximum number of transactions for each batch operation is {total}',
    reviewedSuccessfullyTotal: '{total} transactions have been reviewed successfully',
    restReviewTotal: '{total} transactions require manual specification of events',
    subscriptionExpired: 'Your subscription has expired. Please purchase again.',
    subscriptionLimitError: 'Your usage has exceeded the limit. Please upgrade your plan.',
    syncIntegrationSuccess: 'entries has been synchronized to {platform}',
    disconnectWithPlatformInfo:
      'We will clear the corresponding relationship with {platform}. When you want to sync again, you need to re-associate.',
    authorizationFailed: 'Authorization failed',
    syncIntegrationInfo:
      'Journal entries are being generated, please wait for the generation to complete before syncing again.',
    connectedSuccessfully: 'Successfully connected to {platform}',
    successfullyDisconnected: 'Successfully disconnected with {platform}',
    accountMappingMessage: 'Account mappings will show here after synchronization is completed.',
    success: 'Success',
    resendInvitationEmailSuccess: 'Resend invitation email successfully',
    sourceImportedSuccess:
      'You have successfully imported {total} transactions, and it appears that {total} of them require review.',
    someLinesError: 'Some lines in this file are not recognized because of formatting errors, missing content, etc.',
    importCSVErrorInfo: "Couldn't find any transaction matching {account}. No transactions to be imported.",
    transactionsWillBeImported: 'Transactions will be imported',
    transactionsWillBeImportedAccount: 'Transactions will be imported to {account}',
    uploadCSVWarningInfo:
      'Only transactions that match the selected Account will be imported, and other unrelated transactions will be discarded.',
    deleteAttachmentInfo: 'Are you sure you want to delete this attachment of {fileName}?',
    sourceSyncTimeoutInfo: "Your address may require a longer sync time. We'll notify you once it's complete."
  },
  input: {
    nameLabel: 'Your Name',
    passwordsLabel: 'Password (8-16 characters)',
    setPasswordsLabel: 'Set a password (8-16 characters)'
  },
  placeholder: {
    enterEmail: 'Enter your email address',
    enterNumber: 'Enter your number',
    enterPassword: 'Enter your password',
    enterName: 'Enter your name',
    passwordCharacters: '8-16 characters',
    min: 'min',
    max: 'max'
  },
  country: {
    HongKong: 'Hong Kong',
    Japan: 'Japan',
    Philippines: 'Philippines',
    Singapore: 'Singapore',
    SouthKorea: 'South Korea',
    UnitedKingdom: 'United Kingdom',
    UnitedStates: 'United States'
  },
  bank: {
    星展银行: 'DBS Bank',
    汇丰银行: 'HSBC',
    中银香港: 'BOA HK',
    花旗银行: 'Citibank',
    东亚银行: 'Bank of East Asia',
    华侨银行: 'OCBC Bank',
    工银亚洲: 'ICBC (Asia)',
    德意志银行: 'Deutsche Bank',
    渣打银行: 'Standard Chartered',
    恒生银行: 'Hang Seng Bank',
    富国银行: 'Wells Fargo',
    美国银行: 'Bank of America',
    大华银行: 'UOB Bank',
    瑞士银行: 'UBS',
    巴克莱银行: 'Barclays Bank',
    摩根大通: 'J.P.Morgan',
    其他银行: 'Other Bank'
  },
  project: {
    nav: {
      financials: 'Financials',
      fundReports: 'Fund Report',
      metrics: 'Metrics',
      charts: 'Charts',
      balanceSheet: 'Balance Sheet',
      incomeStatement: 'Income Statement',
      cashFlowStatement: 'Cash Flow Statement',
      generalLedger: 'General Ledger',
      transactions: 'Transactions',
      wallets: 'Wallets',
      portfolioStatement: 'Portfolio Statement',
      totalReturn: 'Total Return',
      changeInNetAssets: 'Change in Net Assets',
      tokenFlowStatement: 'Token Flow Statement',
      sources: 'Sources',
      accounts: 'Accounts',
      contacts: 'Contacts',
      rules: 'Rules',
      disclosure: 'Disclosure',
      SignificantHoldings: 'Significant Holdings',
      RestrictionsCryptoAssets: 'Restrictions of Crypto Assets',
      Restrictions: 'Restrictions',
      rollForward: 'Crypto Assets Roll Forward',
      RollForward: 'Roll Forward',
      cryptoAssetsActivitySheet: 'Crypto Assets Activity Sheet',
      forReview: 'For Review',
      duplicates: 'Duplicates',
      reviewed: 'Reviewed',
      categorized: 'Categorized'
    }
  },
  bubbleGuide: {
    forReview: {
      step1:
        'For Review: you will first need to review your transactions here. <br/> Duplicates: you need to check for duplicates here. <br/>Categorized: reviewed transactions.',
      step2: 'Complete review of a single transaction by clicking the checkmark button',
      step3: 'Batch review button, you can review the filtered transactions together'
    },
    duplicates: {
      step1:
        'If you confirm that a transaction is a duplicate, you can set the event for this transaction to ignore by clicking this button',
      step2:
        'If you confirm that none of these transactions are duplicates, you can remove them from the duplicate list by clicking this button'
    },
    balanceSheet: {
      step1: 'Regenerate financial reports by clicking this button'
    }
  },
  log: {
    addSource: "Add source '{sourceName}'",
    editSource: "Edit source '{sourceName}'",
    deleteSource: "Delete source '{sourceName}'",
    uploadCsv: "Upload CSV '{fileName}'",
    reviewTransaction: "Review transaction '{activityId}'",
    batchReviewTransaction: "review {total} transactions '{activityIds}'",
    editTransactionDetail: "Edit transaction detail '{activityId}'",
    batchTransactionDetail: "Edit {total} transactions detail '{activityIds}'",
    exportTransaction: 'Export {total} transactions',
    regenerateReports: 'Regenerate reports',
    addContact: "Add contact '{contactName}'",
    batchAddContact: "Add {total} contacts '{contactNames}'",
    editContact: "Edit contact '{contactName}'",
    deleteContact: "Delete contact '{contactName}'",
    batchDeleteContact: "Delete {total} contacts '{contactNames}'",
    addRule: "Add rule '{ruleName}'",
    editRule: "Edit rule '{ruleName}'",
    deleteRule: "Delete rule '{ruleName}'",
    manuallyExecuteRules: 'Manually execute all rules',
    addAccount: "Add account '{accountName}'",
    editAccount: "Edit account '{accountName}'",
    deleteAccount: "Delete account '{accountName}'",
    batchDeleteAccount: "Delete {total} accounts '{accountNames}'"
  },
  menus: {
    'My Projects': 'My Projects',
    sources: 'Sources',
    Reports: 'Reports',
    reports: 'Reports',
    transactions: 'Transactions',
    ledger: 'Ledger',
    contacts: 'Contacts',
    automation: 'Automation',
    settings: 'Settings',
    Fundamentals: 'Fundamentals',
    'Money Flows': 'Money Flows',
    Social: 'Social',
    alert: 'Alert',
    rules: 'Rules',
    history: 'History',
    market: 'Market',
    marketMap: 'Market Map',
    emergingSpaces: 'Emerging Spaces',
    heatmap: 'Heatmap',
    screeners: 'My Screener'
  }
}
